import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from "@angular/core";

import { SharingService } from "@core/api/sharing.service";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";
import { arrayToDataString } from "@core/services/creatives/image-helpers";
import { HelperService } from "@core/services/helper.service";

import { CModalComponent } from "@theme/@confect/components/modal/modal.component";
import {
  CreativePreviewerInterface,
  CreativePreviewerPageRequest,
  CreativePreviewerResult,
  CreativesPreviewerComponent,
} from "@theme/components/creatives-previewer/creatives-previewer.component";

import { Observable, Subscriber } from "rxjs";

@Component({
  selector: "ngx-creatives-preview-window",
  templateUrl: "./creatives-preview-window.component.html",
  styleUrls: ["./creatives-preview-window.component.scss"],
  standalone: false,
})
export class CreativesPreviewWindowComponent
  implements OnInit, CreativePreviewerInterface
{
  private sharingService = inject(SharingService);
  private copy = inject(HelperService);

  @Input() editor: CreativesEditService | null | undefined = null;

  @Input() spec: any = {};
  @Input() selectedFilters = {};

  @Input() resolution: [number, number] = [1080, 1080];

  @Input() showVariants = false;

  @Input() cols: number = 3;

  _mode = "image";

  @Input()
  set mode(to) {
    this._mode = to;
    this.previewer?.reload();
  }

  @Output() renderError = new EventEmitter<any>();
  @Output() closeWindow = new EventEmitter();

  @ViewChild("creativesPreviewTemplate", { static: true })
  creativesPreviewTemplate: TemplateRef<any>;

  @ViewChild("previewer") previewer: CreativesPreviewerComponent;

  @ViewChild("shareLinkModal", { static: true })
  private shareLinkModal: CModalComponent;

  currentPreviewUrl = "";

  copyButtonText = "Copy URL";

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit() {}

  nextPage(
    request: CreativePreviewerPageRequest,
  ): Observable<CreativePreviewerResult> {
    return new Observable((observer) => {
      const resolution = this.resolution;

      if (this._mode === "image") {
        this.imagePreview(observer, request, resolution);
      } else if (this._mode === "video") {
        this.videoPreview(observer, request, resolution);
      } else {
        observer.error({ message: "Editor mode is not set" });
      }
    });
  }

  imagePreview(
    observer: Subscriber<CreativePreviewerResult>,
    request: CreativePreviewerPageRequest,
    resolution: number[],
  ) {
    if (this.editor != null) {
      observer.next({
        progress: -1,
      });

      this.editor.live.socketService
        .renderManyImages(
          this.editor.getSpec(),
          this.resolution,
          request.filters,
          request.page,
        )
        .subscribe({
          next: (res) => {
            switch (res.type) {
              case "progress":
                observer.next({ progress: res.progress, results: null });
                break;
              case "result":
                observer.next({
                  progress: 100,
                  results: res.result.map((u) => ({
                    url: arrayToDataString(u, "image/jpg"),
                    result_type: "image",
                  })),
                });
                observer.complete();
                break;
              case "error":
                observer.error(res.error);
                break;
            }
          },
          error: (err) => {
            this.renderError.emit(err.error.info);
            this.closeWindow.emit();
          },
        });
      return;
    }
  }

  videoPreview(
    observer: Subscriber<CreativePreviewerResult>,
    request: CreativePreviewerPageRequest,
    resolution: number[],
  ) {
    if (this.editor != null) {
      observer.next({
        progress: -1,
      });

      this.editor.live.socketService
        .renderVideo(
          this.editor.getSpec(),
          this.resolution,
          request.filters,
          // request.page
        )
        .subscribe({
          next: (res) => {
            switch (res.type) {
              case "progress":
                observer.next({ progress: res.progress, results: null });

                break;
              case "result":
                observer.next({
                  progress: 100,
                  results: [{ url: res.result, result_type: "video" }],
                });
                observer.complete();
                break;
              case "error":
                observer.error(res.error);
                break;
            }
          },
          error: (err) => {
            this.renderError.emit(err.error.info);
            this.closeWindow.emit();
          },
        });
      return;
    }
  }

  generateShareLinkClicked() {
    this.sharingService
      .generateCreativeShareLink(
        this.editor ? this.editor.getCleanSpec() : this.spec,
        {
          previewMode: this._mode,
          resolution: this.resolution,
          selectedFilters: this.selectedFilters,
          showFilters: false,
        },
      )
      .subscribe((code) => {
        this.currentPreviewUrl = `https://app.confect.io/sharing/creative?code=${code.code}`;
        this.shareLinkModal.show();
      });
  }

  copyText(text) {
    this.copyButtonText = "Copied!";
    this.copy.copyToClipboard(text);
    setTimeout(() => {
      this.copyButtonText = "Copy URL";
    }, 3000);
  }
}
