import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from "@angular/core";

import { FolderService } from "@core/api/folder.service";
import { MediaService } from "@core/api/media.service";

import {
  FolderContents,
  FoldersComponent,
  ItemDelegate,
  TypeSettings,
} from "@components/folders";

import { EmptyStateInfo } from "@theme/components/empty-state/empty-state.component";
import { MediaLibrarySourceService } from "@theme/components/media-library/media-library-source.service";

import { Observable, map, of } from "rxjs";

@Component({
  selector: "ngx-shared-fonts",
  templateUrl: "./shared-fonts.component.html",
  standalone: false,
})
export class SharedFontsComponent
  implements ItemDelegate<any>, OnInit, OnDestroy
{
  protected folderService = inject(FolderService);
  private mediaService = inject(MediaService);

  _radioMode: boolean = false;
  empty_size: "default" | "small" = "default";
  mediaSources;

  page = 1;
  allowNext = true;

  @Input() set folderType(to: "media_visual" | "media_fonts") {}

  @Input() empty_state: EmptyStateInfo = {
    img: "assets/images/empty-states/media-library.png",
    title: "You don't have any media.",
    description:
      "Upload media such as logos and backgrounds to use in your designs",
  };

  public source: MediaLibrarySourceService;

  settings: TypeSettings = {
    header: true,
    grid: false,
    noImage: true,
    listHeader: [
      { name: "name", size: 5 / 10 },
      { name: "example", size: 5 / 10 },
    ],
    type: "media_fonts",
    display_type: { item: "Font", particle: "a" },
    searchable: true,
    hasFolders: false,
  };

  formatType = {
    otf: "opentype",
    woff: "woff",
    woff2: "woff2",
    ttf: "truetype",
  };

  @Output() mediaSelect: any = new EventEmitter<any>();

  @ViewChild("folders") folders: FoldersComponent<any>;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    const mediaService = this.mediaService;

    this.source = new MediaLibrarySourceService(mediaService);
  }

  loadFolder(): Observable<FolderContents<any>> {
    if (
      this.folders?.searchString != null &&
      this.folders.searchString !== ""
    ) {
      this.page = 1;
    }
    return this.mediaService
      .getMedia(
        this.source.key,
        this.page,
        20,
        "shared",
        this.folders?.searchString ?? "",
      )
      .pipe(
        map((val) => {
          this.allowNext = val.length === 20;

          const old = document.getElementById("fonts");
          old != null && old.remove();

          const node = document.createElement("style");
          node.setAttribute("id", "fonts");
          document.body.appendChild(node);
          val.forEach((item) => {
            this.addFont(item, node);
          });
          return {
            name: null,
            id: null,
            items: val,
            folders: [],
          };
        }),
      );
  }

  renameItem(item: any): Observable<boolean> {
    return this.mediaService.updateMedia(item.id, {
      source: this.source.key,
      media: { name: item.name },
    });
  }
  deleteItem(item: any): Observable<boolean> {
    return of(true);
  }
  duplicateItem(item: any): Observable<number> {
    return of(1);
  }
  openItem(item: any) {}
  actionsActivated(action: string, item: any) {}
  getItemImage(item: any) {}

  addFont(item, node) {
    const fontStr =
      item.variants.regular?.url ??
      (Object.values(item.variants)[0] as any).url;
    const styles = `
    @font-face {
      font-family: 'font${item.id}';
      src: url(${fontStr}) format('${
        this.formatType[fontStr.split(".").pop()]
      }')
    }
    div.font${item.id} {
      font-family: font${item.id} !important
    }
    `;

    node.innerHTML += styles;
  }
  ngOnInit(): void {
    this.source.configure("uploaded_shared_fonts", "shared");
  }

  ngOnDestroy(): void {
    const style = document.getElementById("fonts");
    style != null && style.remove();
  }

  next() {
    this.page += 1;
    this.folders.reloadFolder();
  }
  prev() {
    this.page -= 1;
    this.folders.reloadFolder();
  }
}
