import {
  CreativeEditorMode,
  CreativeLayer,
  CreativePreviewSettings,
  CreativeSpec,
} from "@core/models/creative.types";
import { CMath } from "@core/utils/confect-math";

import { CreativeError } from "@app/pages/creatives/creatives-edit/types/creative-error";
import { resolutions } from "app/pages/creatives/creatives-edit/creatives-resolutions";
import { Subject, catchError, map, of, takeUntil, zip } from "rxjs";
import * as uuid from "uuid";

import { CreativesEditService } from "./creatives-edit.service";
import {
  CreativeLiveServiceConfig,
  CreativesLiveService,
} from "./creatives-live.service";
import { arrayToCSSImage } from "./image-helpers";
import { iterateSpecificLayers } from "./layout-helpers";
import { copySpec, taintLayers } from "./spec-copy-helper";

export interface DesignFormatSpec {
  display: string;
  key: string;
  resolution: [number, number];
  hide?: boolean;
}

export interface LayerTaint {
  position?: boolean;
  opacity?: boolean;
  properties?: Record<string, boolean>;
}

export interface DesignFormat {
  uuid: string;
  key: string; // E.G. 9_16
  resolution: [number, number] | null;
  tainted_layers: Record<string, LayerTaint>;
}

export interface DesignSpec {
  default: string;
  formats: DesignFormat[];
  specs: Record<string, CreativeSpec>;
  type: CreativeEditorMode;
}

export interface Design {
  id?: number;
  name: string;
  spec: DesignSpec;
  preview_settings: CreativePreviewSettings;
  num_product_cells?: number;
  ui_folder?: number;
  live_image_cache: any;
}

export class CreativesEditMasterService {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  formatChangeSource: Subject<boolean> = new Subject<boolean>();
  formatChange$ = this.formatChangeSource.asObservable();
  error$: Subject<any> = new Subject<any>();

  // Preview filter
  selectedPreviewFilters = {};

  formats: Record<string, DesignFormatSpec>;
  savedFormats: number = 1;
  design: Design | null;

  currentFormat: string;
  editor: CreativesEditService = null;
  live: CreativesLiveService = null;

  resolutions = resolutions;
  objectKeys = Object.keys;
  resOptions = this.objectKeys(this.resolutions);
  selectedResolution = "1_1";

  editorMode: CreativeEditorMode = CreativeEditorMode.IMAGE;

  loading = false;
  ready = false;
  artBoardThumbnails: Record<
    string,
    { css: string; resolution: [number, number] }
  >;

  raisedError: CreativeError = null;

  constructor(
    design: Design | null,
    formats: Record<string, DesignFormatSpec>,
    company: number,
    account: number,
    proctor: number,
    has_feed: boolean,
  ) {
    this.design = design;
    this.formats = formats;
    this.savedFormats = this.design?.spec.formats.length ?? 1;
    this.currentFormat = design?.spec.default;

    this.loadEditorServices(company, account, proctor, has_feed);
  }

  setDefaultOpacity(spec) {
    const specCopy = JSON.parse(JSON.stringify(spec));
    const layers = Array.from(
      iterateSpecificLayers(specCopy.sections[0].layers),
    );
    layers.map((layer) => {
      if (layer.LAYER.opacity == null) {
        layer.LAYER.opacity = 100;
      }
      return layer;
    });

    return specCopy;
  }

  createDesign(spec) {
    const defaultUUID = uuid.v4();
    return {
      name: "New Design",
      spec: {
        default: defaultUUID,
        formats: [
          {
            uuid: defaultUUID,
            key: "1_1",
            resolution: null,
            tainted_layers: {},
          },
        ],
        specs: { [defaultUUID]: spec },
        type: CreativeEditorMode.IMAGE,
      },
      preview_settings: {},
      live_image_cache: {},
    };
  }

  destroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.live.destroy();
  }

  loadEditorServices(
    company: number,
    account: number,
    proctor: number,
    has_feed: boolean,
  ) {
    const ps: CreativePreviewSettings = this.design
      ? this.design.preview_settings
      : {};
    const cachedImages = this.design?.live_image_cache ?? {};

    if (this.design != null) {
      const specCopy = JSON.parse(JSON.stringify(this.design.spec.specs));
      Object.entries(specCopy).forEach(([key, value]) => {
        specCopy[key] = this.setDefaultOpacity(value);
      });
      this.design.spec.specs = specCopy;
    }

    this.editor = new CreativesEditService(
      this.design?.spec.specs[this.design?.spec.default],
      this.design?.name,
      has_feed,
    );

    setTimeout(() => {
      if (this.design == null) {
        this.design = this.createDesign(this.editor.getSpec());
      }

      this.editorMode =
        (this.design.spec.type as CreativeEditorMode) ??
        CreativeEditorMode.IMAGE;

      const defaultUUID = this.design.spec.default;

      this.currentFormat = defaultUUID;

      const fromats = this.design.spec.formats;
      const formatIdx = fromats.findIndex(
        (format) => format.uuid === defaultUUID,
      );

      // We need to configure our live service
      const liveConfig: CreativeLiveServiceConfig = {
        editor: this.editor,
        company: company,
        account: account,
        proctor: proctor,
        previewFilters: this.selectedPreviewFilters,
        resolution: fromats[formatIdx].resolution ??
          this.formats[fromats[formatIdx].key]?.resolution ?? [1080, 1080],
      };

      this.selectedResolution = fromats[formatIdx].key;
      if (this.selectedResolution === "custom") {
        this.resolutions["custom"].size = fromats[formatIdx].resolution;
      }

      // Activate live mode for the editor
      this.live = this.editor.activateLiveMode(liveConfig);
      this.live.checkLayers();

      // Load cached images
      if (cachedImages) {
        this.live.loadInitialImages(cachedImages);
      }

      this.setupCallbacks();

      if (Object.values(this.formats).length > 1) {
        this.loadArtBoard();
      }

      this.ready = true;
    });
  }

  syncLayers(spec: CreativeSpec, currentSpec: string) {
    const whitelist = [["position"], ["LAYER", "opacity"]];
    const specCopy = JSON.parse(JSON.stringify(spec));

    const designCopy = JSON.parse(JSON.stringify(this.design));
    const designSpec = designCopy.spec;
    designSpec.specs[currentSpec] = specCopy;
    const defaultSpec = designSpec.specs[designSpec.default];
    const currentFormat = designSpec.formats.find(
      (format) => format.uuid === currentSpec,
    );
    const taintedLayers = currentFormat.tainted_layers;
    if (currentSpec !== designSpec.default) {
      taintLayers(specCopy, defaultSpec, whitelist, taintedLayers);
      designSpec.specs[designSpec.default] = copySpec(
        designSpec.specs[designSpec.default],
        specCopy,
        whitelist,
        taintedLayers,
      );
    }

    designSpec.formats.forEach((format) => {
      if (format.uuid !== currentSpec && format.uuid !== designSpec.default) {
        designSpec.specs[format.uuid] = copySpec(
          designSpec.specs[format.uuid],
          designSpec.specs[designSpec.default],
          whitelist,
          { ...taintedLayers, ...format.tainted_layers },
        );
      }
    });

    this.design = designCopy;
  }

  overwriteSpec(fromUUID: string, toUUID: string) {
    const designCopy: Design = JSON.parse(JSON.stringify(this.design));
    designCopy.spec.specs[toUUID] = JSON.parse(
      JSON.stringify(designCopy.spec.specs[fromUUID]),
    );
    this.design = designCopy;
    this.reloadSpec();
  }

  loadArtBoard() {
    const observables = Object.entries(this.design.spec.specs)
      .filter(([key, spec]) => {
        const formats = this.design.spec.formats.find(
          (format) => format.uuid === key,
        );
        return formats != null;
      })
      .map(([key, spec]) => {
        const formats = this.design.spec.formats.find(
          (format) => format.uuid === key,
        );

        const res = CMath.fitResolution(
          formats?.resolution ?? this.formats[formats?.key].resolution,
          250,
          250,
        );
        return this.getArtBoardThumbnail(spec, res)
          .pipe(catchError((err) => of({ error: err })))
          .pipe(
            map((val) => {
              return { key: key, value: val };
            }),
          );
      });
    zip(...observables)
      .pipe(
        map((res) => {
          const combine = {};
          res.forEach((r) => {
            combine[r.key] = r.value;
          });
          return combine;
        }),
      )
      .subscribe({
        next: (
          res: Record<
            string,
            { css?: string; resolution?: [number, number]; error?: any }
          >,
        ) => {
          const artBoard = res;
          const errors = Object.entries(res).filter(
            ([key, value]) => value.error != null,
          );

          errors.forEach(([key, error]) => {
            delete artBoard[key];
            // this.error$.next(error.error.info);
          });

          // if (errors.length === 0) {
          //   this.error$.next(null);
          // }
          this.artBoardThumbnails = res as Record<
            string,
            { css: string; resolution: [number, number] }
          >;
        },
      });
  }

  updateArtBoard() {
    if (this.artBoardThumbnails == null) {
      return;
    }
    this.getArtBoardThumbnail(
      this.editor.getCleanSpec(),
      CMath.fitResolution(
        this.formats[
          this.design.spec.formats.find(
            (format) => format.uuid === this.currentFormat,
          ).key
        ].resolution,
        250,
        250,
      ),
    ).subscribe({
      next: (res) => {
        const artBoardCopy = JSON.parse(
          JSON.stringify(this.artBoardThumbnails),
        );
        artBoardCopy[this.currentFormat] = res;
        this.artBoardThumbnails = artBoardCopy;
      },
      error: (err) => {
        delete this.artBoardThumbnails[this.currentFormat];
      },
    });
  }

  getArtBoardThumbnail(spec: CreativeSpec, resolution: [number, number]) {
    return this.live.socketService
      .renderSingleImage(
        spec,
        resolution,
        this.selectedPreviewFilters,
        this.live.productOffset,
      )
      .pipe(
        map((res) => {
          return {
            css: arrayToCSSImage(res.image, res.format),
            resolution: resolution,
          };
        }),
      );
  }

  getCleanDesign() {
    // this.syncLayers(this.editor.getSpec(), this.currentFormat);
    this.commitLayerChanges();
    return this.design;
  }

  setEditorMode(editorMode: CreativeEditorMode) {
    this.selectedResolution = "1_1";
    this.editorMode = editorMode;
    this.design.spec.type = editorMode;
  }

  setMainFormat(formatUUID: string) {
    const hasFormat: boolean = this.design.spec.formats.some(
      (format) => format.uuid === formatUUID,
    );

    if (hasFormat) {
      const design = JSON.parse(JSON.stringify(this.design));
      design.spec.default = formatUUID;
      const idx = design.spec.formats.findIndex(
        (format) => format.uuid === formatUUID,
      );
      const defaultFormat = design.spec.formats.splice(idx, 1)[0];
      design.spec.formats.unshift(defaultFormat);
      this.design = design;
    }
  }

  setupCallbacks() {
    this.editor.specChange$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (spec) => {
        this.commitLayerChanges();

        if (this.raisedError != null) {
          this.loadArtBoard();
        } else {
          this.updateArtBoard();
        }

        // this.syncLayers(spec, this.currentFormat);
      },
    });
  }

  syncLayer(layer, key) {
    const designSpec = this.design.spec;

    const formatIdx = designSpec.formats.findIndex(
      (format) => format.uuid === this.currentFormat,
    );
    if (key === "opacity" || key === "position") {
      designSpec.formats[formatIdx].tainted_layers[layer][key] = false;
    } else {
      designSpec.formats[formatIdx].tainted_layers[layer].properties[key] =
        false;
    }

    // this.syncLayers(designSpec.specs[designSpec.default], designSpec.default);

    this.reloadSpec();
    this.editor.deselectLayer();
  }

  commitLayerChanges() {
    const currentSpec = JSON.parse(JSON.stringify(this.editor.getSpec()));
    this.design.spec.specs[this.currentFormat] = currentSpec;
  }

  reloadFormat() {
    this.loading = true;
    const selected = this.selectedResolution;
    delete this.selectedResolution;

    setTimeout(() => {
      this.selectedResolution = selected;
      this.loading = false;
    });
  }

  reloadSpec() {
    const designSpec = this.design.spec;
    this.editor.loadSpec(designSpec.specs[this.currentFormat]);
    setTimeout(() => {
      this.loadArtBoard();
    });
  }

  changeFormat(uuid: string) {
    // const currentSpec = JSON.parse(JSON.stringify(this.editor.getSpec()));
    // this.syncLayers(currentSpec, this.currentFormat);

    this.commitLayerChanges();

    const design = JSON.parse(JSON.stringify(this.design));
    const designSpec = design.spec;
    const format = designSpec.formats.find((format) => format.uuid === uuid);

    this.loading = true;

    this.currentFormat = uuid;
    this.selectedResolution = format.key;

    this.editor.loadSpec(designSpec.specs[uuid]);

    setTimeout(() => {
      this.formatChangeSource.next(true);
      this.loading = false;
    });
  }

  updateFormat(format: DesignFormatSpec) {
    const designCopy: Design = JSON.parse(JSON.stringify(this.design));
    const designSpec = designCopy.spec;
    const currentFormatIdx = designSpec.formats.findIndex(
      (format) => format.uuid === this.currentFormat,
    );
    designSpec.formats[currentFormatIdx].key = format.key;
    if (format.key === "custom") {
      designSpec.formats[currentFormatIdx].resolution = format.resolution;
    }

    this.selectedResolution = format.key;
    if (this.selectedResolution === "custom") {
      this.resolutions["custom"].size = format.resolution;
    }

    this.design = designCopy;
  }

  addFormat(key: string) {
    const designSpec = this.design.spec;

    const newUUID = uuid.v4();
    const newFormat: DesignFormat = {
      key: key,
      uuid: newUUID,
      resolution: null,
      tainted_layers: {},
    };
    const newSpec = JSON.parse(
      JSON.stringify(designSpec.specs[designSpec.default]),
    );
    designSpec.formats.push(newFormat);
    designSpec.specs[newUUID] = newSpec;
    this.design.live_image_cache = {};

    this.editor.deselectLayer();
    this.changeFormat(newUUID);
    setTimeout(() => {
      this.updateArtBoard();
    });
  }
  deleteFormat(key: string) {
    this.editor.deselectLayer();
    this.changeFormat(this.design.spec.default);

    const design: Design = JSON.parse(JSON.stringify(this.design));

    const formatIndex = design.spec.formats.findIndex(
      (format) => format.key === key,
    );
    if (formatIndex === -1) {
      return;
    }

    delete design.spec.specs[design.spec.formats[formatIndex].uuid];
    design.spec.formats.splice(formatIndex);
    this.design = design;
    this.design.live_image_cache = {};

    setTimeout(() => {
      this.loadArtBoard();
    });
  }
  copyLayerToFormat(uuid: string, layerSpecs: CreativeLayer[]) {
    this.changeFormat(uuid);
    setTimeout(() => {
      layerSpecs.forEach((spec) => this.editor.addLayerSpec(spec));
    });
  }
}
