import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from "@angular/core";
import { Router } from "@angular/router";

import { DesignsService } from "@core/api/designs.service";
import { SharedElementsService } from "@core/api/shared-elements.service";
import { CreativeEditorMode, CreativeLayer } from "@core/models/creative.types";
import { AccessService } from "@core/services/access.service";
import {
  CreativesEditMasterService,
  DesignFormat,
  DesignFormatSpec,
} from "@core/services/creatives/creatives-edit-master.service";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";
import { CreativesLiveService } from "@core/services/creatives/creatives-live.service";
import { HotjarService } from "@core/services/hotjar.service";
import { Orientation } from "@core/utils/confect-math";

import { CSlideOverComponent } from "@theme/@confect/components/slide-over/slide-over.component";
import {
  ContextMenuService,
  ContextRef,
} from "@theme/@confect/services/confect-context-menu.service";
import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";
import {
  Pane,
  SplitSpec,
  SplitViewComponent,
} from "@theme/@confect/wrappers/split-view/split-view.component";

import { CreativesPreviewWindowComponent } from "@app/pages/creatives/creatives-preview-window/creatives-preview-window.component";

import { resolutions } from "../../creatives-resolutions";
import { CreativeEditPropertiesComponent } from "../creative-edit-properties/creative-edit-properties.component";
import { CreativesEditGridV3Component } from "../creatives-edit-grid-v3/creatives-edit-grid-v3.component";
import { CreativesEditTimelineComponent } from "../creatives-edit-timeline/creatives-edit-timeline.component";
import { ACADEMY_ARTICLES } from "./articles";

@Component({
  selector: "confect-creatives-editor",
  templateUrl: "./creatives-edit-editor.component.html",
  styleUrls: ["./creatives-edit-editor.component.scss"],
  standalone: false,
})
export class CreativesEditEditorComponent implements OnInit, AfterViewInit {
  private elementService = inject(SharedElementsService);
  private popUpService = inject(CPopupModalService);
  private designsService = inject(DesignsService);
  private hj = inject(HotjarService);
  private contextService = inject(ContextMenuService);
  private accessService = inject(AccessService);
  private router = inject(Router);

  public readonly CreativeEditorMode = CreativeEditorMode;
  _master: CreativesEditMasterService = null;
  @Input() set master(to: CreativesEditMasterService) {
    this._master = to;
    this.setCurrentFormat();
  }
  @Input() editor: CreativesEditService = null;
  @Input() live: CreativesLiveService = null;

  @Input() videoSupport: boolean = false;
  @Input() allowModeSwitching: boolean = true;
  @Input() allowResolutionChange: boolean = true;
  @Input() showPreview: boolean = true;
  @Input() forceSingleProduct: boolean = false;

  @Input() extraSettings = { minimalUser: true };
  @Input() helpEnabled = true;
  @Input() specs: any = {};

  actualFormat: DesignFormat;

  _formatSpecList: DesignFormatSpec[];
  @Input() set formatSpecList(to: DesignFormatSpec[]) {
    this._formatSpecList = JSON.parse(JSON.stringify(to));
    this.setCurrentFormat();
  }

  _formatSpec: Record<string, DesignFormatSpec> | null;
  @Input() set formatSpec(to: Record<string, DesignFormatSpec> | null) {
    this._formatSpec = JSON.parse(JSON.stringify(to));
    this.setCurrentFormat();
  }

  @Input() previewFilterOptions = [];
  @Input() currentLiveResolution: [number, number] = [500, 500];

  dropDown: ContextRef;
  groupDropDown: ContextRef;
  multiDropDown: ContextRef;

  @ViewChild("grid", { static: false }) grid: CreativesEditGridV3Component;
  @ViewChild("canvas", { static: false }) canvas: ElementRef;
  @ViewChild("split") splitView: SplitViewComponent;
  @ViewChild("timeline") timeline: CreativesEditTimelineComponent;
  @ViewChild("previewWindow") previewWindow: TemplateRef<any>;
  @ViewChild("customResolutionModal") customResolutionModal: TemplateRef<any>;
  @ViewChild("noVideoSupportRes") noVideoSupportRes: TemplateRef<any>;
  @ViewChild("previewProducts", { static: true })
  previewProductsSlideover: CSlideOverComponent;
  @ViewChild("previewer") previewer: CreativesPreviewWindowComponent;
  @ViewChild("propertiesBox") properties: CreativeEditPropertiesComponent;
  @ViewChild("formatSettings") formatSettings: TemplateRef<any>;
  @ViewChild("copyToFormat") copyToFormatPrompt: TemplateRef<any>;

  @Output() resolutionChange = new EventEmitter<any>();

  @Output() syncLayer = new EventEmitter<{ layer: string; key: string }>();

  imageSpec: SplitSpec = {
    draggable: true,
    layout: {
      basis: 27,
      max: 35,
      min: 27,
      pane: Pane.Second,
      unit: "rem",
    },
    orientation: Orientation.Vertical,
    split: {
      first: {
        draggable: false,
        minimizable: true,
        layout: {
          basis: 0,
          max: 30,
          min: 0,
          pane: Pane.Second,
          unit: "%",
        },
        orientation: Orientation.Horizontal,
        splitID: "artBoard",
        split: {
          first: "canvas",
          second: "artBoard",
        },
      },
      second: {
        draggable: true,
        layout: {
          basis: 50,
          max: 80,
          min: 20,
          pane: Pane.First,
          unit: "%",
        },
        orientation: Orientation.Horizontal,
        split: {
          first: "layers",
          second: "properties",
        },
      },
    },
  };

  videoSpec: SplitSpec = {
    draggable: true,
    layout: {
      basis: 27,
      max: 35,
      min: 27,
      pane: Pane.Second,
      unit: "rem",
    },
    orientation: Orientation.Vertical,
    split: {
      first: {
        draggable: true,
        layout: {
          basis: 30,
          min: 30,
          max: 65,
          unit: "%",
          pane: Pane.Second,
        },
        orientation: Orientation.Horizontal,
        split: {
          first: "canvas",
          second: "timeline",
        },
      },
      second: "properties",
    },
  };

  academyArticles = ACADEMY_ARTICLES;

  videoAllowed: boolean = false;
  formatsAllowed: boolean = false;
  viewInit: boolean = false;

  multiDropDownOptions = [
    {
      display: "Group",
      function: (layers) => {
        const group = layers.filter((layer) => layer.type === "group");
        group.length > 0
          ? layers.forEach((layer) => {
              if (layer.type !== "group") {
                this.editor.addToGroup(layer, group[0]);
              }
            })
          : this.editor.groupSelectedLayers();
      },
      hide: (layers) => {
        return (
          layers.filter((layer) => {
            return layer.type === "group";
          }).length > 1
        );
      },
      icon: "folder_outlined",
      keybind: "Ctrl+G",
    },
    {
      display: "Copy",
      function: (layer) => {
        this.editor.copyLayers();
      },
      hide: (layer) => false,
      disable: (layer) => false,
      keybind: "Ctrl+C",
      icon: "paste",
    },
    {
      display: "Paste",
      function: (layer) => {
        this.editor.pasteLayers();
      },
      hide: (layer) => false,
      disable: (layer) => false,
      keybind: "Ctrl+V",
      icon: "survey",
    },
    {
      display: "Bring forward",
      function: (layers) => {
        this.editor.moveMultiSelect(layers, "up");
      },
      hide: (layers) => {
        const subLayers = layers.every(
          (layer) => layer.parent === layers[0].parent,
        );
        return !subLayers;
      },
      keybind: "Ctrl+↑",
      icon: "bring_forward",
    },
    {
      display: "Send backward",
      function: (layers) => {
        this.editor.moveMultiSelect(layers, "down");
      },
      hide: (layers) => {
        const subLayers = layers.every(
          (layer) => layer.parent === layers[0].parent,
        );
        return !subLayers;
      },
      keybind: "Ctrl+↓",
      icon: "send_backward",
    },
    {
      display: "Copy layer to format",
      function: (layers) => {
        const layersCopy = JSON.parse(JSON.stringify(layers));
        this.openLayerToFormat(layersCopy);
      },
      hide: (layers) => !this.formatsAllowed || !this.forceSingleProduct,
      disable: (layers) =>
        layers.some(
          (layer) =>
            layer.config?.grid_config[0].type === "product" &&
            this.forceSingleProduct,
        ) || this._master?.design.spec.formats?.length === 1,
      disableMsg:
        this._master?.design.spec.formats?.length === 1
          ? "You only have one format"
          : "You cannot copy a product layer",
      icon: "formats",
    },
    {
      display: "Save as Element",
      function: (layers) => {
        const layerGroup = this.editor.groupLayers(layers);
        this.elementService.saveLayerGroup(
          layerGroup,
          this.designsService,
          this.popUpService,
        );
      },
      hide: (layers) =>
        !layers.every(
          (layer) => layer.parent == null && layer.type !== "group",
        ),
      icon: "save_outlined",
    },
  ];

  dropDownOptions = [
    {
      display: "Rename",
      function: (layer) => {
        this.editor.editLayer(layer.identifier, this.popUpService);
      },
      hide: (layer) => false,
      icon: "edit",
    },
    {
      display: "Duplicate",
      function: (layer) => {
        this.editor.duplicateLayer(layer.identifier);
      },
      hide: (layer) => false,
      disable: (layer) =>
        layer.config?.grid_config[0].type === "product" &&
        this.forceSingleProduct,
      disableMsg: "You cannot duplicate a product layer",
      keybind: "Ctrl+D",
      icon: "copy",
    },
    {
      display: "Copy",
      function: (layer) => {
        this.editor.copyLayers();
      },
      hide: (layer) => false,
      disable: (layer) => false,
      keybind: "Ctrl+C",
      icon: "paste",
    },
    {
      display: "Paste",
      function: (layer) => {
        this.editor.pasteLayers();
      },
      hide: (layer) => false,
      disable: (layer) => false,
      keybind: "Ctrl+V",
      icon: "survey",
    },
    {
      display: "Bring forward",
      function: (layer) => {
        this.editor.moveLayer(layer, "up");
      },
      hide: (layer) => false,
      keybind: "Ctrl+↑",
      icon: "bring_forward",
    },
    {
      display: "Send backward",
      function: (layer) => {
        this.editor.moveLayer(layer, "down");
      },
      hide: (layer) => false,
      keybind: "Ctrl+↓",
      icon: "send_backward",
    },
    {
      display: "Copy layer to format",
      function: (layer) => {
        const layerCopy = JSON.parse(JSON.stringify(layer));
        this.openLayerToFormat([layerCopy]);
      },
      hide: (layer) => !this.formatsAllowed || !this.forceSingleProduct,
      disable: (layer) =>
        (layer.config?.grid_config[0].type === "product" &&
          this.forceSingleProduct) ||
        this._master?.design.spec.formats?.length === 1,
      disableMsg:
        this._master?.design.spec.formats?.length === 1
          ? "You only have one format"
          : "You cannot copy a product layer",
      icon: "formats",
    },
    {
      display: "Delete",
      function: (layer) => {
        this.editor.removeLayer(layer.identifier);
      },
      hide: (layer) => false,
      disable: (layer) =>
        layer.config?.grid_config[0].type === "product" &&
        this.forceSingleProduct,
      disableMsg: "You cannot delete a product layer",
      icon: "delete_outlined",
    },
  ];

  groupDropDownOptions = [
    {
      display: "Rename",
      function: (layer) => {
        this.editor.editLayer(layer.identifier, this.popUpService);
      },
      hide: (layer) => false,

      icon: "edit",
    },
    {
      display: "Duplicate",
      function: (layer) => {
        this.editor.duplicateLayer(layer.identifier);
      },
      hide: (layer) => false,
      disable: (layer) => {
        return layer.layers.some(
          (l) => l.config?.grid_config[0].type === "product",
        );
      },
      disableMsg:
        "<div class='w-52'>You cannot duplicate a product layer. Remove product layer from group first.</div>",
      icon: "copy",
      keybind: "Ctrl+D",
    },
    {
      display: "Copy",
      function: (layer) => {
        this.editor.copyLayers();
      },
      hide: (layer) => false,
      disable: (layer) => false,
      keybind: "Ctrl+C",
      icon: "paste",
    },
    {
      display: "Paste",
      function: (layer) => {
        this.editor.pasteLayers();
      },
      hide: (layer) => false,
      disable: (layer) => false,
      keybind: "Ctrl+V",
      icon: "survey",
    },
    {
      display: "Save as Element",
      function: (layer) => {
        this.elementService.saveLayerGroup(
          layer,
          this.designsService,
          this.popUpService,
        );
      },
      hide: (layer) => false,
      icon: "save_outlined",
    },
    {
      display: "Copy layer to format",
      function: (layer) => {
        const layerCopy = JSON.parse(JSON.stringify(layer));
        this.openLayerToFormat([layerCopy]);
      },
      hide: (layer) => !this.formatsAllowed || !this.forceSingleProduct,
      disable: (layer) => {
        return layer.layers.some(
          (l) =>
            l.config?.grid_config[0].type === "product" ||
            this._master?.design.spec.formats?.length === 1,
        );
      },
      disableMsg:
        this._master?.design.spec.formats?.length === 1
          ? "You only have one format"
          : "<div class='w-52'>You cannot copy a product layer. Remove product layer from group first.</div>",
      icon: "formats",
    },
    {
      display: "Bring forward",
      function: (layer) => {
        this.editor.moveLayer(layer, "up");
      },
      hide: (layer) => false,
      keybind: "Ctrl+↑",
      icon: "bring_forward",
    },
    {
      display: "Send backward",
      function: (layer) => {
        this.editor.moveLayer(layer, "down");
      },
      hide: (layer) => false,
      keybind: "Ctrl+↓",
      icon: "send_backward",
    },

    {
      display: "Delete",
      function: (layer) => {
        this.editor.removeLayer(layer.identifier);
      },
      hide: (layer) => false,
      disable: (layer) =>
        layer.layers.some((l) => l.config.grid_config[0].type === "product") &&
        this.forceSingleProduct,
      disableMsg:
        "You cannot delete a product layer. Remove product layer from group first.",
      icon: "delete_outlined",
    },
  ];

  filteredCount: number;
  totalCount: number;

  /* Zoom */
  maxZoom = 3.0;
  minZoom = 0.5;
  zoomFactor = 0.1;

  hideBalloon: boolean = false;
  balloonDebounce;

  currentZoom = 1;
  /* End zoom */

  allowSnap: boolean = true;

  scrollOffset: { x: number; y: number } = { x: 0, y: 0 };

  // Video render flags
  resolutions = resolutions;
  objectKeys = Object.keys;
  objectEntries = Object.entries;
  resOptions = this.objectEntries(this.resolutions).map(([key, value]) => {
    return {
      key: key,
      value: value.name,
    };
  });

  previewShow = false;

  currentPreviewResolution: [number, number] = [1080, 1080];

  previewFiltersChanged = false;

  hasLicense: boolean = false;
  subscriptionV2: boolean = false;

  defaultFormatResolution: string;

  hasChangedResolution: boolean = false;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    this.videoAllowed = this.accessService.hasFeature("video");
    this.formatsAllowed = this.accessService.hasProPlus();
    this.hasLicense = this.accessService.current.account?.has_license === true;
    this.subscriptionV2 =
      this.accessService.current.company.subscription_v2 === true;
  }

  ngOnInit() {}
  ngAfterViewInit(): void {
    this.viewInit = true;
  }

  openLayerToFormat(layers: CreativeLayer[]) {
    this.popUpService.template({
      template: this.copyToFormatPrompt,
      showClose: false,
      extra: {
        layers: layers,
        currentFormat: this._master.currentFormat,
        formats: this._master.design.spec.formats.map((format) => {
          const formatCopy = JSON.parse(JSON.stringify(format));
          formatCopy["display"] = this._formatSpec[format.key].display;
          return formatCopy;
        }),
      },
    });
  }

  setCurrentFormat() {
    if (
      this._master == null ||
      this._formatSpec == null ||
      this._formatSpecList == null
    ) {
      return;
    }
    this.actualFormat = this._master.design.spec.formats.find(
      (format) => format.uuid === this._master.currentFormat,
    );

    if (this._master.editorMode === CreativeEditorMode.VIDEO) {
      this._formatSpecList = this._formatSpecList.filter(
        (formatSpec) => formatSpec.key !== "video",
      );
    }

    this._formatSpecList.forEach((formatSpec) => {
      this._formatSpec[formatSpec.key].hide = false;
    });

    this._master.design.spec.formats.forEach((format) => {
      this._formatSpec[format.key].hide = true;
    });
  }

  rightClick(event, item) {
    this.groupDropDown?.close(event);
    this.dropDown?.close(event);
    this.multiDropDown?.close(event);

    if (this.editor.multiSelectedLayerIdentifiers.size > 0) {
      this.multiDropDown = this.contextService.open({
        options: this.multiDropDownOptions,
        event: event,
        item: item,
      });
      this.multiDropDown.afterClose.subscribe({
        next: () => {
          this.multiDropDown = null;
        },
      });

      return;
    }
    if (this.editor.layer.type === "group") {
      this.groupDropDown = this.contextService.open({
        options: this.groupDropDownOptions,
        event: event,
        item: item,
      });
      this.groupDropDown.afterClose.subscribe({
        next: () => {
          this.groupDropDown = null;
        },
      });

      return;
    }

    this.dropDown = this.contextService.open({
      options: this.dropDownOptions,
      event: event,
      item: item,
    });

    this.dropDown.afterClose.subscribe({
      next: () => {
        this.dropDown = null;
      },
    });
  }

  update() {
    this.editor.layerChangedSource.next(true);
  }

  scrollZoom(event: any) {
    event.preventDefault();

    const width = this.canvas.nativeElement.offsetWidth;
    const height = this.canvas.nativeElement.offsetHeight;

    const scaleByHeight =
      (height / this.currentLiveResolution[1]) * this.currentLiveResolution[0] <
      width;
    const innerResX = scaleByHeight
      ? (height / this.currentLiveResolution[1]) * this.currentLiveResolution[0]
      : width;
    const innerResY = scaleByHeight
      ? height
      : (width / this.currentLiveResolution[0]) * this.currentLiveResolution[1];

    const maxOffsetVertical =
      Math.max(innerResY * 0.7 * this.currentZoom - height + 50, 0) / 2;
    const maxOffsetHorizontal =
      Math.max(innerResX * 0.7 * this.currentZoom - width + 50, 0) / 2;

    this.scrollOffset.x = Math.max(
      Math.min(this.scrollOffset.x - event.deltaX / 10, maxOffsetHorizontal),
      -maxOffsetHorizontal,
    );
    this.scrollOffset.y = Math.max(
      Math.min(this.scrollOffset.y - event.deltaY / 10, maxOffsetVertical),
      -maxOffsetVertical,
    );

    if (event.ctrlKey || event.metaKey) {
      this.currentZoom = Math.max(
        Math.min(
          this.currentZoom - Math.max(Math.min(event.deltaY, 10), -10) / 50,
          this.maxZoom,
        ),
        this.minZoom,
      );
    }

    this.editor.zoomChangedSource.next(this.currentZoom);
  }

  changeMode(to: CreativeEditorMode) {
    this._master.setEditorMode(to);
    this._formatSpecList = this._formatSpecList.filter(
      (formatSpec) => formatSpec.key !== "video",
    );
    this.hj.event(
      this._master.editorMode === CreativeEditorMode.VIDEO
        ? "video_editor_loaded"
        : "image_editor_loaded",
    );
    this.splitView.reload();
    this.live.checkLayers();
  }

  // Is everything loaded so that we can show editor?
  isLoaded() {
    return (
      this.specs != null && this.resolutions != null && this.editor != null
    );
  }

  screenSize() {
    return {
      width: this.canvas.nativeElement.clientWidth,
      height: this.canvas.nativeElement.clientHeight,
    };
  }

  _fitResolution(
    targetResolution: [number, number],
    widthDim: number,
    heightDim: number,
  ): [number, number] {
    if (widthDim > targetResolution[0] && heightDim > targetResolution[1]) {
      return targetResolution;
    }

    const f = Math.max(
      targetResolution[0] / widthDim,
      targetResolution[1] / heightDim,
    );

    return [
      Math.round(targetResolution[0] / f),
      Math.round(targetResolution[1] / f),
    ];
  }

  @HostListener("window:keydown", ["$event"])
  shortcutListener(event: KeyboardEvent) {
    const shift = event.shiftKey;
    const alt = event.altKey;
    const mod = event.ctrlKey || event.metaKey;
    const code = event.code;

    // Disable shortcuts on input focus
    const tn = document.activeElement.tagName;
    if (tn === "INPUT" || tn === "TEXTAREA") {
      return;
    }

    if (mod) {
      switch (code) {
        case "KeyZ":
          if (shift) {
            this.editor.history.redo();
          } else {
            this.editor.history.undo();
          }
          return;
        case "KeyY":
          this.editor.history.redo();
          return;
        case "KeyD":
          event.preventDefault();
          if (this.editor.layer == null) {
            return;
          }
          const isGroup = this.editor?.layer?.type === "group";
          const hasProductLayer =
            isGroup &&
            this.editor.layer.layers.some(
              (layer) => layer.config?.grid_config[0].type === "product",
            );
          if (
            this.editor.layer.config?.grid_config[0].type === "product" ||
            hasProductLayer
          ) {
            return;
          }
          this.editor.duplicateLayer(this.editor.layer.identifier);
          return;
        case "KeyG":
          if (shift) {
            event.preventDefault();
            const isGroup = this.editor?.layer?.type === "group";

            if (isGroup) {
              this.editor.desolveGroup(this.editor.layer.identifier);
            }
            return;
          }
          event.preventDefault();
          this.editor.groupSelectedLayers();
          return;
        case "KeyC":
          this.editor.copyLayers();
          return;
        case "KeyV":
          this.editor.pasteLayers();
          return;
        case "ArrowUp":
          event.preventDefault();
          event.stopPropagation();
          if (this.editor?.layer != null) {
            this.editor.moveLayer(this.editor.layer, "up");
          }
          return;
        case "ArrowDown":
          event.preventDefault();
          event.stopPropagation();
          if (this.editor?.layer != null) {
            this.editor.moveLayer(this.editor.layer, "down");
          }
          return;
      }
      return;
    }

    switch (code) {
      case "Delete":
      case "Backspace":
        if (
          this.editor.layer == null &&
          this.editor.multiSelectedLayerIdentifiers.size === 0
        ) {
          return;
        }
        if (
          (this.editor.layer.config?.grid_config[0].type === "product" ||
            this.editor.layer.layers?.some(
              (l) => l.config?.grid_config[0].type === "product",
            )) &&
          this.forceSingleProduct
        ) {
          return;
        }

        this.editor.removeActiveLayer();
        break;
    }
  }

  configurePreviewResolution() {
    const toResolution = this.resolutions[this._master.selectedResolution].size;
    if (this._master.editorMode === CreativeEditorMode.IMAGE) {
      this.currentPreviewResolution = this._fitResolution(
        toResolution,
        2000,
        2000,
      );
    } else {
      this.currentPreviewResolution = this._fitResolution(
        toResolution,
        512,
        512,
      );
    }
  }

  configureResolution() {
    const toResolution = this.resolutions[this._master.selectedResolution].size;

    this.currentLiveResolution = this._fitResolution(toResolution, 1280, 1000);
    this.configurePreviewResolution();

    if (this.live) {
      this.live.resolution = this.currentLiveResolution;
      this.live.emptyImages();
      this.live.invalidateLayers();
    }
  }
  updateFormat(toResolution: [number, number]) {
    if (this._master.selectedResolution === "video") {
      return;
    }
    if (this._master.selectedResolution === "custom") {
      this._master.updateFormat({
        key: "custom",
        display: "Custom",
        resolution: toResolution,
      });
    } else {
      this._master.updateFormat(
        this._formatSpec[this._master.selectedResolution],
      );
    }
  }
  openFormatSettings(key: string, index: number) {
    const chosenUUID = this._master.design.spec.formats.find(
      (format) => format.key === key,
    ).uuid;
    const main = chosenUUID === this._master.design.spec.default;
    const callback = () => {
      this.popUpService.template({
        template: this.formatSettings,
        showClose: false,
        extra: {
          main: main,
          index: index,
          display: this._formatSpec[key].display,
          format: chosenUUID,
          key: key,
          formats: this._master.design.spec.formats.map((format) => {
            const formatCopy = JSON.parse(JSON.stringify(format));
            formatCopy["display"] = this._formatSpec[format.key].display;
            return formatCopy;
          }),
        },
      });
    };
    if (main && !this.hasChangedResolution) {
      this.popUpService
        .warning({
          title: "You are about to change resolution for the main format.",
          text: "Are you sure this is what you want? We recommend adding more formats rather than changing the main resolution.",
          confirmText: "I am sure",
        })
        .outputs["modalClosed"].asObservable()
        .subscribe({
          next: (res) => {
            if (res) {
              callback();
            }
          },
        });
      this.hasChangedResolution = true;
      return;
    }
    callback();
  }

  preview() {
    this.configurePreviewResolution();
    this.previewShow = true;
    this.popUpService
      .template({ template: this.previewWindow, showClose: false })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          this.previewShow = false;
        },
      });
  }

  productFiltersChanged() {
    this.live.previewFilters = this._master.selectedPreviewFilters;
    this.previewFiltersChanged = true;
  }

  applyCustomResolution(resolution: [number, number]) {
    resolutions[this._master.selectedResolution].size = [
      Number(resolution[0]),
      Number(resolution[1]),
    ];
    this.configureResolution();
    this.updateFormat(resolutions[this._master.selectedResolution].size);
  }

  setCustomResolution() {
    const modalRef = this.popUpService.template({
      template: this.customResolutionModal,
      extra: { size: resolutions[this._master.selectedResolution].size },
      showClose: false,
    });
    modalRef.outputs["modalClosed"].asObservable().subscribe({
      next: (res) => {
        this._master.reloadFormat();
        this.live.invalidateLayers();
        this.live.checkLayers();
      },
    });
    modalRef.outputs["escaped"].asObservable().subscribe({
      next: (res) => {
        this._master.reloadFormat();
        this.live.invalidateLayers();
        this.live.checkLayers();
      },
    });
  }

  resolutionChanged() {
    if (this._master.selectedResolution === "custom") {
      this.setCustomResolution();
    } else if (this._master.selectedResolution === "video") {
      const modalRef = this.popUpService.warning({
        title: "Switch to Video",
        text: `${!this.videoSupport ? "Once switched to video, the design cannot be switched back to a static design. A Pro or Global subscription is required to use video designs in active catalogs. Continue?" : "Once you switch to video, you will not be able to switch back. Are you sure this is what you want?"}`,
        cancelText: "Cancel",
        confirmText: "Switch",
      });
      modalRef.outputs["modalClosed"].asObservable().subscribe({
        next: (res) => {
          if (res) {
            this.changeMode(CreativeEditorMode.VIDEO);
          }
          this._master.reloadFormat();
          this.live.invalidateLayers();
          this.live.checkLayers();
        },
      });
      modalRef.outputs["escaped"].asObservable().subscribe({
        next: (res) => {
          this._master.reloadFormat();
          this.live.invalidateLayers();
          this.live.checkLayers();
        },
      });
    } else {
      this.configureResolution();
      this.updateFormat(resolutions[this._master.selectedResolution].size);
      this.live.invalidateLayers();
      this.live.checkLayers();
    }
  }

  switchMainRes(key: string) {
    this.resolutionChanged();
  }

  onRenderError(error) {
    if (!error) {
      return;
    }
    this._master.raisedError = error;
    this.popUpService.error({
      title: "Error",
      text: error.message,
      autoCloseTimeout: 1500,
    });
  }

  reloadPreview() {
    setTimeout(() => {
      this.previewer.previewer?.reload();
    });
  }
  toPlans = () => {
    this.router.navigate(["/settings/billing"], {
      state: {
        tab: "plans",
      },
    });
  };
  toMarkets = () => {
    this.router.navigate(["/settings/markets"]);
  };
}
