<confect-stepper #stepper [pages]="1">
  <confect-step
    [currentStep]="stepper.CURRENT_STEP"
    [info]="{ title: 'Animations', step: 1, primaryButton: 'Save' }"
    [valid]="this.valid"
    invalidText="Total effects duration is longer than the duration of the layer."
    (next)="save.emit(this._animationConfig)"
  >
    <div class="flex w-full 2xl:min-w-[60vw] h-10 mb-5">
      <div class="text-center w-6/12 h-full">
        <div
          class="flex cursor-pointer items-center justify-center w-full h-full rounded-l-xl border-y-2 border-l-2 box-border"
          [ngClass]="{
            ' border-slate-200  border-dotted': !_animationConfig?.effect_in,
            ' border-primary': _animationConfig?.effect_in,
            'bg-white hover:bg-slate-50':
              selected !== 'effect_in' || !_animationConfig?.effect_in,
            'bg-primary text-white':
              selected === 'effect_in' && _animationConfig?.effect_in,
          }"
          (click)="
            selected = 'effect_in';
            showPresets = false;
            !_animationConfig?.effect_in && addEffect()
          "
        >
          @if (!_animationConfig?.effect_in) {
            <confect-icon icon="add" iconClass="text-gray-400 text-3xl">
              <span class="block text-center text-gray-500">Intro</span>
            </confect-icon>
          } @else {
            <span class="block text-center">Intro</span>
          }
        </div>
      </div>
      <div
        class="border-l-2"
        [ngClass]="{
          'border-l-primary ':
            _animationConfig?.effect_in != null ||
            _animationConfig?.effect_out != null,
          'border-slate-200 border-dotted':
            !_animationConfig?.effect_out && !_animationConfig?.effect_in,
        }"
      ></div>

      <!-- <div class="text-center w-4/12 h-full">
        <div
          class="flex cursor-pointer items-center justify-center w-full h-full border-y-2 box-border"
          [ngClass]="{
            ' border-y-slate-200 border-dotted': !_animationConfig?.effect,
            ' border-y-primary': _animationConfig?.effect,
            'bg-white hover:bg-slate-50':
              selected !== 'effect' || !_animationConfig?.effect,
            'bg-primary hover text-white':
              selected === 'effect' && _animationConfig?.effect
          }"
          (click)="
            selected = 'effect';
            _animationConfig['effect'] == null &&
              (_animationConfig['effect'] = [{ effect: '', settings: {} }])
          "
        >
          @if (!_animationConfig?.effect) {
            <confect-icon icon="add" iconClass="text-gray-400 text-3xl">
              <span class="block text-center text-gray-500">Middle</span>
            </confect-icon>
          } @else {
            <span class="block text-center">Middle</span>
          }
        </div>
      </div>
      <div
        class="border-l-2"
        [ngClass]="{
          'border-l-primary ':
            _animationConfig?.effect_out != null ||
            _animationConfig?.effect != null,
          'border-slate-200 border-dotted':
            !_animationConfig?.effect && !_animationConfig?.effect_out
        }"
      ></div> -->
      <div class="text-center w-6/12 h-full">
        <div
          class="flex cursor-pointer items-center justify-center w-full h-full rounded-r-xl border-y-2 border-r-2 box-border bg-white hover:bg-slate-50"
          [ngClass]="{
            ' border-y-slate-200 border-l-slate-200 border-dotted':
              !_animationConfig?.effect_out,
            ' border-primary ': _animationConfig?.effect_out,
            'bg-white hover:bg-slate-50':
              selected !== 'effect_out' || !_animationConfig?.effect_out,
            'bg-primary hover text-white':
              selected === 'effect_out' && _animationConfig?.effect_out,
          }"
          (click)="
            selected = 'effect_out';
            showPresets = false;
            !_animationConfig?.effect_out && addEffect()
          "
        >
          @if (!_animationConfig?.effect_out) {
            <confect-icon icon="add" iconClass="text-gray-400 text-3xl">
              <span class="block text-center text-gray-500">Outro</span>
            </confect-icon>
          } @else {
            <span class="block text-center">Outro</span>
          }
        </div>
      </div>
    </div>
    @if (!_animationConfig[selected] && !loading) {
      <div class="mt-10 h-full">
        <ngx-empty-state [info]="emptyStateInfo" />
      </div>
    } @else {
      <div class="grid grid-cols-2">
        <div>
          <div class="overflow-y-auto max-h-[55vh] p-2 rounded-md">
            <div class="flex flex-col space-y- w-full">
              @if (
                selected === "effect" && _animationConfig[selected] != null
              ) {
                @for (
                  effect of _animationConfig[selected];
                  track effect;
                  let i = $index
                ) {
                  <ngx-effect-edit
                    [maxDuration]="duration"
                    [effect]="effect"
                    (remove)="
                      remove(i);
                      this.changeSubject.next(true);
                      this.validateCalc()
                    "
                    (change)="changeSubject.next(true); this.validateCalc()"
                  />
                  <div class="w-full flex items-center justify-center"></div>
                }
                <div class="flex items-center justify-center w-full">
                  <confect-button type="secondary" (click)="add()"
                    ><confect-icon icon="add" iconClass="text-lg"
                      >Add Effect</confect-icon
                    ></confect-button
                  >
                </div>
              } @else {
                @if (showPresets) {
                  <div
                    class="rounded-sm p-3 bg-white min-w-[350px] ring-2 ring-slate-200"
                  >
                    <div class="w-full flex items-center justify-start mb-5">
                      <confect-icon-button
                        icon="chevron_left"
                        iconClass="text-2xl"
                        (click)="showPresets = false"
                        >Back</confect-icon-button
                      >
                    </div>

                    <div class="grid grid-cols-2 gap-5 text-sm w-full">
                      @for (preset of presets; track preset) {
                        <div
                          class="flex items-center justify-center rounded-md text-center bg-primary-50 py-5 px-3 cursor-pointer ring-inset ring-2 ring-primary hover:text-white hover:bg-primary"
                          (click)="setPreset(preset)"
                        >
                          {{ preset.name }}
                        </div>
                      }
                    </div>
                  </div>
                } @else {
                  <ngx-effect-edit
                    [maxDuration]="duration"
                    [effect]="_animationConfig[selected]"
                    (remove)="
                      remove();
                      this.changeSubject.next(true);
                      this.validateCalc()
                    "
                    (presetChange)="showPresets = true"
                    (change)="changeSubject.next(true); this.validateCalc()"
                  />
                }
              }
            </div>
          </div>
        </div>

        <div
          class="m-2 flex aspect-square items-center w-full justify-center ring-2 ring-slate-200 rounded-sm"
        >
          @if (!previewLoading) {
            <video
              [src]="previewUrl"
              class="object-fill aspect-square w-full rounded-sm"
              muted
              autoplay
              loop
            ></video>
          }
        </div>
      </div>
    }
  </confect-step>
  <!-- <confect-step
    [currentStep]="stepper.CURRENT_STEP"
    [info]="{ title: 'Presets', step: 2 }"
  >
    <div class="grid grid-cols-3 gap-5">
      @for (preset of presets; track preset) {
        <div
          class="w-full h-full rounded-md text-white text-center p-5 cursor-pointer bg-primary-400 ring-inset ring-2 ring-primary hover:bg-primary"
          (click)="setPreset(preset)"
        >
          {{ preset.name }}
        </div>
      }
    </div>
  </confect-step> -->
</confect-stepper>
