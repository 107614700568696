<!-- <div
  class="w-[100vw] h-[100vh] z-[1000] fixed top-0 left-0 flex items-center justify-center"
>
  <div class="absolute bg-gray-500 opacity-30 w-full h-full"></div>
  <ngx-creative-edit-properties
    class="w-[22rem] h-[44rem] z-[1050]"
    #propertiesBox
    [editorMode]="editorMode"
    [editor]="editor"
    [live]="live"
    [specs]="specs"
    [helpEnabled]="helpEnabled"
    [extraSettings]="extraSettings"
    [(selectedPreviewFilters)]="selectedPreviewFilters"
    [previewFilterOptions]="previewFilterOptions"
  >
  </ngx-creative-edit-properties>
</div> -->
<div
  fullscreen
  backgroundColor="#edf1f7"
  [allowOverflow]="false"
  class="fullScreenContainer"
>
  @if (master?.ready) {
    @if (master.raisedError != null) {
      <div
        class="rounded-md bg-red-50 p-4 error-message shadow-sm"
        style="z-index: 21000"
        [ngClass]="{
          'video-mode': master.editorMode === CreativeEditorMode.VIDEO,
          'image-mode': master.editorMode !== CreativeEditorMode.VIDEO,
        }"
      >
        <div class="flex items-center">
          <confect-icon
            icon="error_outlined"
            iconClass="h-5 w-5 text-red-400 text-2xl"
          />
          <div class="ml-3">
            <p class="text-sm font-medium text-red-800">
              Error: {{ master.raisedError.message }}
            </p>
          </div>
          <div class="ml-auto pl-3">
            <div class="-mx-1.5 -my-1.5">
              @if (master.raisedError.error_key === "NOT_ENOUGH_PRODUCTS") {
                <confect-button
                  (click)="editorRef.properties.resetProductFilter()"
                  size="xsmall"
                  type="white"
                  >Reset filter</confect-button
                >
              }
            </div>
          </div>
        </div>
      </div>
    }

    @if (editor?.sequences?.length > 0) {
      <div class="toolbar shadow">
        <div class="left-toolbar inline-flex items-center space-x-8">
          <div>
            <confect-icon-button
              icon="chevron_left"
              iconClass="text-3xl"
              (click)="close()"
              >Designs</confect-icon-button
            >
          </div>
          <div class="inline-flex items-center space-x-2">
            <confect-icon-button
              icon="undo"
              iconClass="text-2xl"
              [disabled]="!editor.history.canUndo"
              (click)="editor.history.undo()"
              tooltip
              tooltipText="Undo"
              tooltipPosition="below"
            />
            <confect-icon-button
              icon="redo"
              iconClass="text-2xl"
              [disabled]="!editor.history.canRedo"
              (click)="editor.history.redo()"
              tooltip
              tooltipText="Redo"
              tooltipPosition="below"
            />
          </div>

          @if (false) {
            <confect-icon-button
              icon="magic_wand"
              iconClass="text-2xl"
              (click)="showAutoEffects()"
            />
          }
        </div>
        <div class="center-toolbar">
          @if (!demoMode) {
            <confect-input
              [(ngModel)]="editor.name"
              placeholder="Design name"
              [centered]="true"
            />
          }
        </div>
        <div class="right-toolbar flex items-center justify-end space-x-3">
          <confect-button
            dialogBoxTarget
            dialogPosition="below"
            targetID="preview"
            type="secondary"
            size="small"
            (click)="editorRef.preview()"
            >Preview</confect-button
          >

          @if (!demoMode) {
            <div>
              <confect-button size="small" (click)="save()"
                >Save</confect-button
              >
            </div>
          }
          @if (demoMode) {
            <div>
              <confect-button
                size="small"
                (click)="freeTrial()"
                class="glow relative py-2"
                [class.glow]="demoMode && demoModePreviewClicked"
                >Start free trial</confect-button
              >
            </div>
          }
        </div>
      </div>
    }

    <!-- (syncLayer)="syncLayer($event.layer, $event.key)" -->
    <confect-creatives-editor
      #editorComponent
      [editor]="editor"
      [master]="master"
      [live]="live"
      [specs]="specs"
      [formatSpec]="formats"
      [formatSpecList]="formatsList"
      [videoSupport]="videoSupport"
      [allowModeSwitching]="true"
      [allowResolutionChange]="true"
      [forceSingleProduct]="true"
      [showPreview]="true"
      [extraSettings]="extraSettings"
      [helpEnabled]="helpEnabled"
      [previewFilterOptions]="previewFilterOptions"
    />
  }
</div>
@if (live != null && !introFinished) {
  <ngx-design-intro
    [live]="live"
    [editor]="editor"
    (done)="endIntro($event)"
    (noProducts)="this.introFinished = true"
  />
}

<ng-template #previewDialog>
  <div class="max-w-[300px]">
    By using the preview button, you can preview any changes made in the editor
    before saving.
  </div>
</ng-template>

<ng-template #elementsDialog>
  <div class="max-w-[300px]">
    Select elements to use in your design. Saved elements will also apear here,
    so they can be reused.
  </div>
</ng-template>
