!function (t, e) {
  "object" == typeof exports && "object" == typeof module ? module.exports = e() : "function" == typeof define && define.amd ? define([], e) : "object" == typeof exports ? exports.ls = e() : t.ls = e();
}(this, () => (() => {
  "use strict";

  var t = {
      d: (e, r) => {
        for (var o in r) t.o(r, o) && !t.o(e, o) && Object.defineProperty(e, o, {
          enumerable: !0,
          get: r[o]
        });
      },
      o: (t, e) => Object.prototype.hasOwnProperty.call(t, e)
    },
    e = {};
  t.d(e, {
    default: () => i
  });
  const r = (...t) => {},
    o = t => null !== t && "Object" === (null == t ? void 0 : t.constructor.name);
  let n, c;
  const l = () => {
      if (!n) {
        n = !0;
        try {
          c = a.storage || localStorage, c.getItem("");
        } catch {
          c = (() => {
            const t = {
              getItem: t => e[t] || null,
              setItem: (t, r) => {
                e[t] = r;
              },
              removeItem: t => {
                e[t] = void 0;
              },
              clear: () => {
                e = {
                  __proto__: t
                };
              }
            };
            let e = {
              __proto__: t
            };
            return e;
          })();
        }
        y();
      }
    },
    s = String.fromCharCode(0),
    p = (t, e, r = !0) => r ? [...JSON.stringify(t)].map(t => String.fromCharCode(t.charCodeAt(0) + e)).join("") : JSON.parse([...t].map(t => String.fromCharCode(t.charCodeAt(0) - e)).join("")),
    a = {
      ttl: null,
      encrypt: !1,
      encrypter: p,
      decrypter: (t, e) => p(t, e, !1),
      secret: 75,
      storage: void 0
    };
  Object.seal(a);
  const y = (t = !1) => {
      l();
      for (const e of Object.keys(c)) {
        const r = c.getItem(e);
        let n;
        try {
          n = JSON.parse(r || "");
        } catch {
          continue;
        }
        o(n) && s in n && (Date.now() > n.ttl || t) && c.removeItem(e);
      }
    },
    i = {
      config: a,
      set: (t, e, o = {}) => {
        l();
        const n = {
          ...a,
          ...o,
          encrypt: !1 !== o.encrypt && (o.encrypt || a.encrypt),
          ttl: null === o.ttl ? null : o.ttl || a.ttl
        };
        try {
          const o = n.ttl && !isNaN(n.ttl) && n.ttl > 0;
          let l = o ? {
            [s]: e,
            ttl: Date.now() + 1e3 * n.ttl
          } : e;
          n.encrypt && (o ? l[s] = (n.encrypter || r)(l[s], n.secret) : l = (n.encrypter || r)(l, n.secret)), c.setItem(t, JSON.stringify(l));
        } catch {
          return !1;
        }
      },
      get: (t, e = {}) => {
        l();
        const n = c.getItem(t),
          p = {
            ...a,
            ...e,
            encrypt: !1 !== e.encrypt && (e.encrypt || a.encrypt),
            ttl: null === e.ttl ? null : e.ttl || a.ttl
          };
        let y, i;
        try {
          y = JSON.parse(n || ""), i = o(y) && s in y, (p.decrypt || p.encrypt) && (i ? y[s] = (p.decrypter || r)(y[s], p.secret) : y = (p.decrypter || r)(y, p.secret));
        } catch {}
        return i ? Date.now() > y.ttl ? (c.removeItem(t), null) : y[s] : void 0 !== y ? y : n;
      },
      flush: y,
      clear: () => {
        l(), c.clear();
      },
      remove: t => {
        l(), c.removeItem(t);
      }
    };
  return e.default;
})());
