import {
  CreativeCell,
  CreativeCellType,
  CreativeLayer,
  CreativeLayerType,
  CreativeSequence,
  CreativeSpec,
} from "@core/models/creative.types";

import ls from "localstorage-slim";
import { Subject } from "rxjs";
import * as uuid from "uuid";

import {
  CreativeLiveServiceConfig,
  CreativesLiveService,
} from "./creatives-live.service";
import { CreativesUndoRedoService } from "./creatives-undo-redo.service";
import { iterateSpecificLayers } from "./layout-helpers";

export type LayerChanged = true | false;

export interface LayerMoved {
  layer: CreativeLayer;
}

export interface LayerAddRemove {
  action: string;
  ident: string;
}

export class CreativesEditService {
  // Loaded data
  sequences: [CreativeSequence];
  name = "";

  // State
  cell: CreativeCell = null;
  sequence: CreativeSequence = null;
  layer: CreativeLayer = null;

  selectedSequenceIndex = 0;
  selectedLayerIdentifier: string;
  activeSelectionType = null;

  multiSelectedLayerIdentifiers = new Set<string>();

  selectedTime = 0;

  // Sources allowing components to react on bigger changes
  cellChangedSource = new Subject<any>();
  layerChangedSource = new Subject<LayerChanged>();
  layerMovedSource = new Subject<LayerMoved>();
  layerAddRemoveSource = new Subject<LayerAddRemove>();
  gridChangedSource = new Subject<any>();
  settingsChangedSource = new Subject<any>();
  timeChangedSource = new Subject<number>();
  layerHoverLayerListSubject = new Subject<CreativeLayer>();
  canvasHoverLayerListSubject = new Subject<CreativeLayer>();
  willChangeLayerSource = new Subject<any>();
  specChangeSource = new Subject<CreativeSpec>();

  zoomChangedSource = new Subject<number>();

  cellChanged$ = this.cellChangedSource.asObservable();
  willChangeLayer$ = this.willChangeLayerSource.asObservable();
  layerChanged$ = this.layerChangedSource.asObservable();
  layerMoved$ = this.layerMovedSource.asObservable();
  layerAddRemove$ = this.layerAddRemoveSource.asObservable();
  gridChanged$ = this.gridChangedSource.asObservable();
  settingsChanged$ = this.settingsChangedSource.asObservable();
  timeChanged$ = this.timeChangedSource.asObservable();
  zoomChanged$ = this.zoomChangedSource.asObservable();
  specChange$ = this.specChangeSource.asObservable();

  flattenedLayers: any[] = [];

  live: CreativesLiveService;
  history: CreativesUndoRedoService;

  hasFeed = true;
  constructor(
    spec: CreativeSpec = null,
    name: string = null,
    hasFeed = true,
    company = null,
  ) {
    this.name = name == null ? "New design" : name;
    this.hasFeed = hasFeed;

    this.history = new CreativesUndoRedoService(this);
    this.loadSpec(spec);
  }

  loadingSpec: boolean = false;

  public addToGroup(layer, group, newIndex = -1) {
    // Hack to make sure layer.parent is set
    Array.from(this.iterateLayers(true));

    const layerList = this.sequence.layers;
    const groupLayerList = this.getLayer(group.identifier).layers;

    const index = layerList.findIndex((l) => l.identifier === layer.identifier);
    layerList.splice(index, 1);

    if (newIndex === -1) {
      groupLayerList.push(layer);
    } else {
      groupLayerList.splice(newIndex, 0, layer);
    }

    // Iterate layers to flush group pointers
    Array.from(this.iterateLayers(true));

    this.layerAddRemoveSource.next(layer);
    this.specChangeSource.next(this.getSpec());
  }

  public moveMultiSelect(layers, direction) {
    // Hack to make sure layer.parent is set

    Array.from(this.iterateLayers(true));

    const layerList = layers[0].parent
      ? this.getLayer(layers[0].parent).layers
      : this.sequence.layers;

    const layerCount = layerList.length;

    const layerIndices: number[] = layers
      .map((layer) => {
        return layerList.findIndex((l) => l.identifier === layer.identifier);
      })
      .sort((a, b) => (a < b ? -1 : 1))
      .reverse();
    const maxIndex = layerIndices[0] - layers.length + 1;
    const minIndex = layerIndices[layers.length - 1];

    const orderedLayers = layerIndices.map((li) => {
      const o = layerList[li];
      layerList.splice(li, 1);
      return o;
    });

    direction === "up" &&
      orderedLayers.forEach((layer) => {
        layerList.splice(Math.max(minIndex - 1, 0), 0, layer);
      });

    direction === "down" &&
      orderedLayers.forEach((layer) => {
        layerList.splice(
          Math.min(maxIndex + 1, layerCount - layers.length),
          0,
          layer,
        );
      });

    // Iterate layers to flush group pointers
    Array.from(this.iterateLayers(true));

    this.layerAddRemoveSource.next(layers);
    this.specChangeSource.next(this.getSpec());
  }

  public moveLayer(layer, direction) {
    // Hack to make sure layer.parent is set
    Array.from(this.iterateLayers(true));

    const layerList = layer.parent
      ? this.getLayer(layer.parent).layers
      : this.sequence.layers;

    const index = layerList.findIndex((l) => l.identifier === layer.identifier);

    if (
      layer.parent &&
      index === (direction === "up" ? 0 : layerList.length - 1)
    ) {
      this.ungroupLayer(layer, direction);
      return;
    }

    layerList.splice(index, 1);
    layerList.splice(
      direction === "up"
        ? Math.max(index - 1, 0)
        : Math.min(index + 1, layerList.length),
      0,
      layer,
    );
    this.layerAddRemoveSource.next(layer);
    this.specChangeSource.next(this.getSpec());
  }

  public loadSpec(spec: CreativeSpec, flush = true) {
    this.loadingSpec = true;
    spec;
    // Deselect cell to prevent loading bugs
    this.deselectCell();

    // Yield for the loop before loading in the next spec
    setTimeout(() => {
      // If we should recreate all underlying services
      const recreate = this.sequences != null;

      const sequences: [CreativeSequence] =
        spec == null ? [this.emptySequence()] : spec.sections;

      if (spec == null) {
        sequences[0].layers[0].LAYER.opacity = 100;
      }

      // Make sure all layers have a type
      for (const layer of iterateSpecificLayers(sequences[0].layers)) {
        layer.type = layer.type ?? CreativeLayerType.LAYER;
      }

      this.sequences = sequences;

      // We make sure row/col weigts sum up to a larger number
      this._migration__sliceToBoundBox(Array.from(this.iterateLayers(true)));

      this.selectSequence(0);

      this.flattenedLayers = Array.from(this.iterateLayers());

      // this.selectFirstLayer();

      if (flush) {
        // Flush history
        this.history.redoStack.flush();
        this.history.undoStack.flush();
        this.history.canUndo = false;
        this.history.canRedo = false;
      }

      this.cellChanged$.subscribe((c) => {
        this.updateSelectionType(c);
      });

      this.updateSelectionType(this.cell);
      this.loadingSpec = false;
    });
  }

  // We keep track of the current cell type
  updateSelectionType(c?: CreativeCell) {
    // No cell selected
    if (c == null) {
      this.activeSelectionType = null;
      return;
    }

    // Cell without a type
    if (c.type === "") {
      this.activeSelectionType = null;
      return;
    }

    // Cell with a type
    this.activeSelectionType = c.type.toUpperCase();
  }

  public activateLiveMode(config: CreativeLiveServiceConfig) {
    this.live = new CreativesLiveService(config);
    return this.live;
  }

  //Some designs might be in an old spec using row and col slices. This
  //migrates the old spec to a the new bounding box spec.
  public _migration__sliceToBoundBox(layers: CreativeLayer[]) {
    const _sum = (arr) => arr.reduce((a, b) => a + b, 0);

    for (const layer of layers) {
      // Skip groups
      if (layer.type === CreativeLayerType.GROUP || layer.position != null) {
        continue;
      }

      //Handle horizontal and vertical offsets
      const config = layer.config.grid_config[0].config;
      const position = config?.media_position ?? config?.text_position;

      const posX = parseFloat(position?.x ?? 0);
      const posY = parseFloat(position?.y ?? 0);
      const hOffset: number = !isNaN(posX) ? posX : 0;
      const vOffset: number = !isNaN(posY) ? posY : 0;

      const rows = layer.config.rows;
      const cols = layer.config.cols;
      const rowSum = _sum(rows);
      const colSum = _sum(cols);

      if (position) {
        position.x = 0;
        position.y = 0;
      }

      layer.position = {
        x: (cols[0] / rowSum) * 100 + hOffset,
        y: (rows[0] / colSum) * 100 + vOffset,
        width: (cols[1] / rowSum) * 100,
        height: (rows[1] / colSum) * 100,
      };
    }
  }

  *iterateLayers(includeGroups = false): Generator<CreativeLayer, void, any> {
    if (!this.sequences) {
      return;
    }
    yield* iterateSpecificLayers(this.sequences[0]["layers"], includeGroups);
  }

  // Get final spec for save
  getSpec(sequence: CreativeSequence = null): CreativeSpec {
    return {
      config: {
        music: null,
        music_fade_in: true,
        music_fade_out: true,
      },
      sections: sequence ? [sequence] : this.sequences,
    };
  }

  // Clean spec for save / remove cells that never got a type
  getCleanSpec(): CreativeSpec {
    // No cells are selected as we are going to remove all empty ones
    // this.deselectCell();

    const cleanSingleLayer = (layer) => {
      delete layer["parent"];
      layer.config.grid_config = layer.config.grid_config.filter((cell) => {
        const r = layer.config.rows.length;
        const c = layer.config.cols.length;
        return (
          cell.pos[0] < r &&
          cell.pos[1] < c &&
          cell.pos[0] === 1 &&
          cell.pos[1] === 1
        );
      });
      return layer;
    };

    const s = JSON.parse(JSON.stringify(this.sequences[0]));

    s.layers.forEach((layer) => {
      if (layer.type === CreativeLayerType.GROUP) {
        layer.layers.map((innerLayer) => cleanSingleLayer(innerLayer));
      } else {
        cleanSingleLayer(layer);
      }
    });

    return this.getSpec(s);
  }

  selectSequence(i) {
    this.sequence = this.sequences[i];
  }

  deselectLayer() {
    this.willChangeLayerSource.next(true);
    this.multiSelectedLayerIdentifiers = new Set();
    delete this.selectedLayerIdentifier;
    delete this.layer;
    this.layerChangedSource.next(true);
  }

  selectFirstLayer(): void {
    this.selectLayer(this.iterateLayers(true).next().value["identifier"]);
  }

  // Return all layers selected with multiSelection
  multiSelectedLayers(): CreativeLayer[] {
    if (
      !this.multiSelectedLayerIdentifiers ||
      this.multiSelectedLayerIdentifiers?.size === 0
    ) {
      return null;
    }
    const s = new Set(this.multiSelectedLayerIdentifiers);
    return Array.from(this.iterateLayers(true)).filter((l) =>
      s.has(l.identifier),
    );
  }

  selectLayerMulti(layerIdentifier: string) {
    this.deselectCell();

    // If primary selected layer, we add this layer as well
    if (this.selectedLayerIdentifier) {
      this.multiSelectedLayerIdentifiers.add(this.selectedLayerIdentifier);
      this.selectedLayerIdentifier = null;
      this.layer = null;
    }

    // Toggle off if already selected
    if (this.multiSelectedLayerIdentifiers.has(layerIdentifier)) {
      this.multiSelectedLayerIdentifiers.delete(layerIdentifier);
    } else {
      this.multiSelectedLayerIdentifiers.add(layerIdentifier);
    }

    if (this.multiSelectedLayerIdentifiers.size > 1) {
      this.layerChangedSource.next(true);
    }

    // Fallback to single selection if only 1 layer in multi select
    if (this.multiSelectedLayerIdentifiers.size === 1) {
      this.selectLayer(Array.from(this.multiSelectedLayerIdentifiers)[0]);
    }
  }

  selectLayer(ident?: string) {
    this.willChangeLayerSource.next(true);
    this.layer = this.getLayer(ident);
    this.selectedLayerIdentifier = ident;
    this.deselectCell();
    this.selectCell(1, 1);

    // No multi select anymore
    this.multiSelectedLayerIdentifiers.clear();

    this.layerChangedSource.next(true);
  }

  selectLayerClick(event: MouseEvent, layerIdent: string) {
    if (
      (event.ctrlKey || event.metaKey) &&
      ((this.selectedLayerIdentifier &&
        this.selectedLayerIdentifier !== layerIdent) ||
        this.multiSelectedLayerIdentifiers.size > 0)
    ) {
      this.selectLayerMulti(layerIdent);
      return;
    }

    this.selectLayer(layerIdent);
  }

  selectCell(row, col) {
    // No cells in groups
    if (this.layer.type === CreativeLayerType.GROUP) {
      return;
    }
    this.cell = this.getCell(row, col, true);
    this.cellChangedSource.next(this.cell);
  }

  deselectCell() {
    this.cell = null;
    this.cellChangedSource.next(null);
  }

  reset() {
    this.cell = null;
    this.layer = null;
    this.layerChangedSource.next(null);
    this.cellChangedSource.next(null);
  }

  defaultLayerConfig(cellType: CreativeCellType) {
    switch (cellType) {
      case CreativeCellType.TEXT:
        const extraArgs = {};

        return { text: "Your text", ...extraArgs };
      default:
        return {};
    }
  }

  emptyLayer(
    cellType: CreativeCellType,
    layerType = CreativeLayerType.LAYER,
    full = false,
  ): CreativeLayer {
    return {
      // Name of layer
      name:
        layerType === CreativeLayerType.LAYER
          ? this.nextLayerName(cellType)
          : this.nextGroupLayerName(),

      identifier: uuid.v4(),

      type: layerType,
      layers: layerType == CreativeLayerType.LAYER ? null : [],

      // Layer spec goes here for now
      LAYER: layerType == CreativeLayerType.LAYER ? { opacity: 100 } : null,

      position: full
        ? {
            x: 0,
            y: 0,
            width: 100,
            height: 100,
          }
        : {
            x: 100 * (1 / 6),
            y: 100 * (1 / 6),
            width: 100 * (2 / 3),
            height: 100 * (2 / 3),
          },

      // Config of grid and slices
      config: CreativeLayerType.LAYER
        ? {
            rows: !full ? [167, 667, 167] : [0, 1000, 0],
            cols: !full ? [167, 667, 167] : [0, 1000, 0],

            steps: 3,
            grid_config: [
              {
                pos: [1, 1],
                type: cellType,
                config: this.defaultLayerConfig(cellType),
                step_position: 1,
              },
            ],
          }
        : null,
    };
  }

  emptySequence(): CreativeSequence {
    const l = this.hasFeed
      ? this.emptyLayer(CreativeCellType.PRODUCT)
      : this.emptyLayer(CreativeCellType.SHAPE, CreativeLayerType.LAYER, true);

    if (!this.hasFeed) {
      l.name = "Background";
    }

    return {
      type: "GRID",
      layers: [l],
    };
  }

  getCellInLayer(
    layer: CreativeLayer,
    row: number,
    col: number,
    create: boolean = false,
  ): CreativeCell {
    // Check if current cell exists, otherwise create
    const gc = layer.config.grid_config;

    const c = gc.filter((cell) => cell.pos[0] === row && cell.pos[1] === col);

    if (c.length === 0) {
      if (!create) {
        return null;
      }

      const cnew: CreativeCell = {
        // pos: this.selectedGridItem,
        pos: [row, col],
        type: CreativeCellType.EMPTY,
        step_position: 1,
        config: {},
      };
      gc.push(cnew);
      return cnew;
    }

    // Return cell
    return c[0];
  }

  getCell(row, col, create = false): CreativeCell {
    return this.getCellInLayer(this.layer, row, col, create);
  }

  getGridCellType(row: number, col: number): string {
    const c = this.getCell(row, col);
    if (c === null) {
      return "";
    }

    if (c.type === "bg") {
      return "s";
    }

    return c.type;
  }

  getCellTypeTextFull(cellType: string): string {
    if (cellType === "BG") {
      cellType = "SHAPE";
    }

    return cellType
      .replace("_", " ")
      .toLowerCase()
      .split(" ")
      .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
      .join(" ");
  }

  getCellTypeTextShort(cellType: string): string {
    if (cellType === "BG") {
      cellType = "SHAPE";
    }

    return cellType
      .replace("_", " ")
      .split(" ")
      .map((w) => w.substr(0, 1).toUpperCase())
      .join("");
  }

  moveCells(direction, fromIndex, by) {
    // Shifts all cells from an index and up by a number of indexes
    const gc = this.layer.config.grid_config;
    this.layer.config.grid_config = gc.map((cell) => {
      if (cell.pos[direction] >= fromIndex) {
        // Find all product refs with this cell and move them as well
        for (const otherLayer of this.sequence.layers) {
          for (const otherCell of otherLayer.config.grid_config) {
            if (!("product_reference" in otherCell.config)) {
              continue;
            }

            const ref = otherCell.config.product_reference;

            // If ref is to this cell
            if (
              ref.pos[0] === cell.pos[0] &&
              ref.pos[1] === cell.pos[1] &&
              ref.layer === this.layer.identifier
            ) {
              ref.pos[direction] += by;
            }
          }
        }

        cell.pos[direction] += by;
      }

      return cell;
    });
  }

  deleteGridDirection(direction, index) {
    const rows = this.layer.config.rows;
    const cols = this.layer.config.cols;

    const directionData = direction === 0 ? rows : cols;
    if (directionData.length === 1) {
      return;
    }

    const gc = this.layer.config.grid_config;
    this.layer.config.grid_config = gc.filter((cell) => {
      return cell.pos[direction] !== index;
    });

    this.moveCells(direction, index + 1, -1);
    directionData.splice(index, 1);
    this.gridChangedSource.next(true);
  }

  deleteGridRow(rowi) {
    this.deleteGridDirection(0, rowi);
  }

  deleteGridCol(coli) {
    this.deleteGridDirection(1, coli);
  }

  addDirection(direction, index) {
    const rows = this.layer.config.rows;
    const cols = this.layer.config.cols;
    this.moveCells(direction, index, 1);
    (direction === 0 ? rows : cols).splice(index, 0, 1);
  }

  addRowAt(index) {
    this.addDirection(0, index);
    this.gridChangedSource.next(true);
  }

  addColAt(index) {
    this.addDirection(1, index);
    this.gridChangedSource.next(true);
  }

  // LAYERS
  // ------
  private _nextName(prefix): string {
    if (this.selectedSequenceIndex === undefined) {
      return `${prefix} 1`;
    }

    const layerNames = new Set(
      Array.from(this.iterateLayers(true)).map((l) => l.name),
    );

    // Look for first non-conflicting layer
    let dupeCount = 1;

    while (true) {
      const newName = `${prefix} ${dupeCount}`;

      if (!layerNames.has(newName)) {
        return newName;
      }

      dupeCount++;
      continue;
    }
  }

  nextLayerName(cellType: CreativeCellType): string {
    switch (cellType) {
      case CreativeCellType.PRODUCT:
        return this._nextName("Product");
      case CreativeCellType.TEXT:
        return this._nextName("Text");
      case CreativeCellType.PRODUCT_ASSET:
        return this._nextName("Asset");
      case CreativeCellType.MEDIA:
        return this._nextName("Media");
      case CreativeCellType.SHAPE:
        return this._nextName("Shape");
      default:
        return this._nextName("Layer");
    }
  }

  nextGroupLayerName(): string {
    return this._nextName("Group");
  }

  addLayer(cellType: CreativeCellType) {
    this.history.beginSpecSnapshot();

    const layers = this.sequence.layers;

    const newLayer = this.emptyLayer(cellType);
    layers.unshift(newLayer);
    this.selectFirstLayer();

    this.layerAddRemoveSource.next({
      action: "ADD",
      ident: newLayer.identifier,
    });

    this.history.finishSpecSnapshot();
    this.specChangeSource.next(this.getSpec());
  }

  addLayerSpec(spec: CreativeLayer) {
    this.history.beginSpecSnapshot();
    spec.identifier = uuid.v4();

    spec.layers?.forEach((l) => (l.identifier = uuid.v4()));

    const layers = this.sequence.layers;
    layers.unshift(spec);
    this.selectFirstLayer();
    this.layerAddRemoveSource.next({
      action: "ADD",
      ident: spec.identifier,
    });

    this.history.finishSpecSnapshot();
    this.specChangeSource.next(this.getSpec());
  }

  groupLayers(layers) {
    const layersCopy = JSON.parse(JSON.stringify(layers));
    const layerGroup = this.emptyLayer(null, CreativeLayerType.GROUP);
    layerGroup.layers = layersCopy;
    return layerGroup;
  }

  addGroup(layers) {
    // Abort if group amongst selection
    if (layers.some((l) => l.type === CreativeLayerType.GROUP)) {
      return;
    }

    const firstLayer = layers.pop();

    this.history.beginSpecSnapshot();

    // New group
    const layerGroup = this.emptyLayer(null, CreativeLayerType.GROUP);

    // Remove layers from their current location
    this._removeLayer(layers.map((l) => l.identifier));

    // Layers of group are the selected layers
    layerGroup.layers = layers;

    // Insert layergroup in top
    this.sequence.layers.unshift(layerGroup);

    this.addToGroup(firstLayer, layerGroup);

    this.history.finishSpecSnapshot();
    this.specChangeSource.next(this.getSpec());
  }

  desolveGroup(groupID: string) {
    const group = this.getLayer(groupID);

    this.history.beginSpecSnapshot();
    const newLayers = group.layers.reverse();
    const layerList = this.sequence.layers;
    const index = layerList.findIndex((l) => l.identifier === group.identifier);
    this.deselectLayer();
    layerList.splice(index, 1);
    newLayers.forEach((l) => {
      layerList.splice(index, 0, l);
      this.multiSelectedLayerIdentifiers.add(l.identifier);
    });

    //Make sure all ids are updated correctly
    Array.from(this.iterateLayers(true));

    this.history.finishSpecSnapshot();

    this.layerAddRemoveSource.next({
      ident: group.identifier,
      action: "remove",
    });
    this.layerChangedSource.next(true);
    this.specChangeSource.next(this.getSpec());
  }

  ungroupLayer(layer, direction) {
    if (layer.parent == null) {
      return;
    }

    const group = this.getLayer(layer.parent);

    this.history.beginSpecSnapshot();

    const newLayers = group.layers.filter(
      (l) => l.identifier !== layer.identifier,
    );

    group.layers = newLayers;

    const layerList = this.sequence.layers;

    const index = layerList.findIndex((l) => l.identifier === group.identifier);
    layerList.splice(
      direction === "up"
        ? Math.max(index, 0)
        : Math.min(index + 1, layerList.length),
      0,
      layer,
    );

    this.history.beginSpecSnapshot();

    this.layerAddRemoveSource.next({ ident: layer.identifier, action: "add" });
    this.specChangeSource.next(this.getSpec());
  }

  groupSelectedLayers() {
    const m = this.multiSelectedLayers();

    if (!m) {
      return;
    }

    const layers = m;

    // Abort if group amongst selection
    if (layers.some((l) => l.type === CreativeLayerType.GROUP)) {
      return;
    }

    const firstLayer = layers.pop();

    this.history.beginSpecSnapshot();

    // New group
    const layerGroup = this.emptyLayer(null, CreativeLayerType.GROUP);

    // Remove layers from their current location
    this._removeLayer(layers.map((l) => l.identifier));

    // Layers of group are the selected layers
    layerGroup.layers = layers;

    // Insert layergroup in top
    this.sequence.layers.unshift(layerGroup);

    this.addToGroup(firstLayer, layerGroup);

    this.deselectLayer();
    this.selectLayer(layerGroup.identifier);

    this.history.finishSpecSnapshot();

    this.layerAddRemoveSource.next({
      ident: layerGroup.identifier,
      action: "ADD",
    });
    this.specChangeSource.next(this.getSpec());
  }

  duplicateLayer(layerIdent) {
    this.history.beginSpecSnapshot();
    const toCopy = this.getLayer(layerIdent);

    const oldIdents = [
      toCopy.identifier,

      // Add identifiers of all layers in group to replace as well
      ...(toCopy.type === CreativeLayerType.GROUP
        ? toCopy.layers.map((l) => l.identifier)
        : []),
    ];

    // New layers by replacing old idents with new ones

    const newLayerString = oldIdents.reduce((prevJSON, curIdent) => {
      return prevJSON.replaceAll(curIdent, uuid.v4());
    }, JSON.stringify(toCopy));

    // Make sure new layer has new identity and name
    const newLayer: CreativeLayer = JSON.parse(newLayerString);

    // Determine name of new top level item
    const name =
      newLayer.type === CreativeLayerType.GROUP
        ? this.nextGroupLayerName()
        : this.nextLayerName(this.getCellInLayer(newLayer, 1, 1).type);

    // New name by cell type in the layer
    newLayer.name = name;

    // Add to top
    this.sequence.layers.unshift(newLayer);

    this.selectFirstLayer();

    this.layerAddRemoveSource.next({
      action: "ADD",
      ident: newLayer.identifier,
    });

    this.history.finishSpecSnapshot();
    this.specChangeSource.next(this.getSpec());
  }

  copyLayers() {
    function copyLayer(layer: CreativeLayer) {
      const layerCopy = JSON.parse(JSON.stringify(layer));
      if (layerCopy.type === CreativeLayerType.GROUP) {
        layerCopy.layers = layerCopy.layers.filter(
          (l) => l.config.grid_config[0].type !== "product",
        );
      }
      const oldIdents = [
        layerCopy.identifier,

        // Add identifiers of all layers in group to replace as well
        ...(layerCopy.type === CreativeLayerType.GROUP
          ? layerCopy.layers.map((l) => l.identifier)
          : []),
      ];

      // New layers by replacing old idents with new ones

      const newLayerString = oldIdents.reduce((prevJSON, curIdent) => {
        return prevJSON.replaceAll(curIdent, uuid.v4());
      }, JSON.stringify(layerCopy));

      // Make sure new layer has new identity and name
      const newLayer: CreativeLayer = JSON.parse(newLayerString);
      return newLayer;
    }
    function isProductLayer(layer) {
      const isProduct = layer.config?.grid_config[0].type === "product";
      const isGroup = layer.type === "group";
      const hasOnlyProduct =
        isGroup &&
        layer.layers.length === 1 &&
        layer.layers.some(
          (layer) => layer.config?.grid_config[0].type === "product",
        );
      return isProduct || hasOnlyProduct;
    }

    const layerCopy = [];
    if (this.layer == null && this.multiSelectedLayerIdentifiers.size === 0) {
      return;
    }

    if (this.layer != null) {
      if (isProductLayer(this.layer)) {
        return;
      }
      layerCopy.push(copyLayer(this.layer));
    }
    if (this.multiSelectedLayerIdentifiers.size > 0) {
      const layers = this.multiSelectedLayers();
      layers.forEach((l) => {
        if (isProductLayer(l)) {
          return;
        }
        layerCopy.push(copyLayer(l));
      });
    }
    ls.set("clipboard", { value: layerCopy }, { encrypt: true });
  }

  pasteLayers() {
    function copyLayer(layer: CreativeLayer) {
      const oldIdents = [
        layer.identifier,

        // Add identifiers of all layers in group to replace as well
        ...(layer.type === CreativeLayerType.GROUP
          ? layer.layers.map((l) => l.identifier)
          : []),
      ];

      // New layers by replacing old idents with new ones

      const newLayerString = oldIdents.reduce((prevJSON, curIdent) => {
        return prevJSON.replaceAll(curIdent, uuid.v4());
      }, JSON.stringify(layer));

      // Make sure new layer has new identity and name
      const newLayer: CreativeLayer = JSON.parse(newLayerString);
      return newLayer;
    }

    const clipboard = (ls.get("clipboard", { decrypt: true }) as any)?.value;

    if (clipboard == null || clipboard.length === 0) {
      return;
    }

    this.history.beginSpecSnapshot();

    this.deselectLayer();

    const newLayers = clipboard.map((layer) => copyLayer(layer));

    // Add to top
    this.sequence.layers.unshift(...newLayers);

    newLayers.forEach((l) => {
      this.selectLayerMulti(l.identifier);
    });

    this.layerAddRemoveSource.next({
      action: "ADD",
      ident: newLayers[0].identifier,
    });

    this.history.finishSpecSnapshot();
    this.specChangeSource.next(this.getSpec());
  }

  toggleHideLayer(layerIdent) {
    const layer = this.getLayer(layerIdent);
    const isHidden = layer["hidden"] === true;
    layer["hidden"] = !isHidden;
  }

  editLayer(layerIdent, popUpService) {
    const l = this.getLayer(layerIdent);

    const prefix = l.type === CreativeLayerType.LAYER ? "Layer" : "Layer group";

    popUpService
      .input({
        title: `${prefix} name`,
        type: "text",
        value: l.name,
        invalidText: "Your layer needs a name",
      })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          if (!res || res.name === l.name) {
            return;
          }
          this.history.beginLayerSnapshot(l);
          l.name = res;
          this.history.finishLayerSnapshot(l);
          this.layerChangedSource.next(true);
          this.specChangeSource.next(this.getSpec());
        },
      });
  }

  updateLayerTimes(layerIdent, offset, duration) {
    const l = this.getLayer(layerIdent);
    this.history.beginLayerSnapshot(l);
    l.LAYER["total_duration"] = duration;
    l.LAYER["layer_offset"] = offset;
    this.history.finishLayerSnapshot(l);
    this.layerChangedSource.next(true);
    this.specChangeSource.next(this.getSpec());
  }

  updateLayerPosition(layerIdent, position) {
    const l = this.getLayer(layerIdent);
    this.history.beginLayerSnapshot(l);
    l.position = position;
    this.history.finishLayerSnapshot(l);
    this.layerChangedSource.next(true);
    this.specChangeSource.next(this.getSpec());
  }

  updateLayerEffects(layerIdent, effects) {
    const l = this.getLayer(layerIdent);
    l.config.grid_config[0].config.effect = effects.effect;
    l.config.grid_config[0].config.effect_in = effects.effect_in;
    l.config.grid_config[0].config.effect_out = effects.effect_out;
    this.history.finishLayerSnapshot(l);
    this.layerChangedSource.next(true);
    this.specChangeSource.next(this.getSpec());
  }

  setOpacity(layerIdent, opacity) {
    const l = this.getLayer(layerIdent);
    this.history.beginLayerSnapshot(l);
    l.LAYER.opacity = opacity;
    this.history.finishLayerSnapshot(l);
    this.layerChangedSource.next(true);
    this.specChangeSource.next(this.getSpec());
  }

  // Removes the active layer(s)
  removeActiveLayer() {
    const m = this.multiSelectedLayers();
    const layerIdents = m
      ? m.map((l) => l.identifier)
      : [this.selectedLayerIdentifier];
    this.removeLayer(...layerIdents);
  }

  // Remove layers without snapshotting
  private _removeLayer(layerIdents: string[]) {
    const idents = new Set(layerIdents);

    this.sequence.layers = this.sequence.layers.filter((outerLayer) => {
      const hasOuter = idents.has(outerLayer.identifier);

      // If has outer we remove it
      if (hasOuter) {
        return false;
      }
      // Is a regular layer and not to remove, we include it
      else if (outerLayer.type === CreativeLayerType.LAYER) {
        return true;
      }

      // Is a group
      // Filter inner group layers
      outerLayer.layers = outerLayer.layers.filter(
        (innerLayer) => !idents.has(innerLayer.identifier),
      );

      // Include group
      return true;
    });

    this.flushEmptyLayerGroups();

    // If out of layers, add a default product
    if (this.sequence.layers.length === 0) {
      this.addLayer(CreativeCellType.PRODUCT);
    }

    if (idents.has(this.selectedLayerIdentifier)) {
      this.selectFirstLayer();
    }

    this.gridChangedSource.next(true);

    this.layerAddRemoveSource.next({
      action: "REMOVE",
      ident: "any",
    });
    this.specChangeSource.next(this.getSpec());
  }

  removeLayer(...layerIdents: string[]) {
    this.history.beginSpecSnapshot();
    this._removeLayer(layerIdents);
    this.history.finishSpecSnapshot();
  }

  layerMoved(layer: CreativeLayer) {
    this.selectLayer(layer.identifier);
    this.gridChangedSource.next(true);
    this.layerMovedSource.next({ layer: layer });
    this.specChangeSource.next(this.getSpec());
  }

  flushEmptyLayerGroups() {
    // Only layers we keep are non-groups and groups with > 0 layers
    this.sequence.layers = this.sequence.layers.filter(
      (l) =>
        l.type !== CreativeLayerType.GROUP ||
        (l.type === CreativeLayerType.GROUP && l.layers.length > 0),
    );

    // Iterate layers to flush group pointers
    Array.from(this.iterateLayers(true));
  }

  getLayer(layerIdent: string): CreativeLayer {
    for (const layer of this.iterateLayers(true)) {
      if (layer["identifier"] === layerIdent) {
        return layer;
      }
    }
  }

  layerOffset(layerIdent: string): number {
    const l = this.getLayer(layerIdent);
    const offset = l.LAYER["layer_offset"];
    return offset ? parseFloat(offset as string) : 0;
  }

  layerDuration(layerIdent: string): number {
    const l = this.getLayer(layerIdent);
    const dur = l.LAYER["total_duration"];
    return dur ? parseFloat(dur as string) : 10;
  }

  // ------------------------

  // Outside event notifiers
  specValueChanged(specItem) {
    this.settingsChangedSource.next(specItem);
    this.specChangeSource.next(this.getSpec());
  }

  setTime(to) {
    this.selectedTime = to;
    this.timeChangedSource.next(to);
  }
}
