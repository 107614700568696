import { Location } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { ElementOverviewSection } from "@core/api-models/shared-elements.models";
import { DesignsService } from "@core/api/designs.service";
import {
  Creative,
  CreativeEditorMode,
  CreativePreviewSettings,
} from "@core/models/creative.types";
import { AccessService } from "@core/services/access.service";
import {
  CreativesEditMasterService,
  Design,
  DesignFormatSpec,
} from "@core/services/creatives/creatives-edit-master.service";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";
import { CreativesLiveService } from "@core/services/creatives/creatives-live.service";
import { effects } from "@core/services/creatives/preset-effects";
import { CustomFieldsService } from "@core/services/custom-fields.service";
import { HomeActionsService } from "@core/services/home-actions-service";
import { HotjarService } from "@core/services/hotjar.service";
import { ProctorService } from "@core/services/proctor.service";
import { UserService } from "@core/services/user.service";

import { CSlideOverComponent } from "@theme/@confect/components/slide-over/slide-over.component";
import { DialogBoxTransmitterService } from "@theme/@confect/services/confect-dialog-box-transmitter.service";
import { HelpService } from "@theme/@confect/services/confect-help.service";
import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

import { uuid4 } from "@sentry/core";
import { Observable, Subject, zip } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { CreativesEditEditorComponent } from "../creatives-edit/components/creatives-edit-editor/creatives-edit-editor.component";

interface MousePosition {
  top: number;
  left: number;
  x: number;
  y: number;
}

@Component({
  selector: "ngx-creatives-edit-v2",
  standalone: false,
  templateUrl: "./creatives-edit-v3.component.html",
  styleUrls: ["./creatives-edit.component.scss"],
})
export class CreativesEditV2Component implements OnInit, OnDestroy {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private location = inject(Location);
  private designsService = inject(DesignsService);
  private proctor = inject(ProctorService);
  private accessService = inject(AccessService);
  private ref = inject(ChangeDetectorRef);
  private fs = inject(CustomFieldsService);
  private us = inject(UserService);
  private ps = inject(CPopupModalService);
  private ha = inject(HomeActionsService);
  private hj = inject(HotjarService);
  private dialogTransmitter = inject(DialogBoxTransmitterService);
  private helpService = inject(HelpService);

  public readonly CreativeEditorMode = CreativeEditorMode;

  @ViewChild("autoEffectsSlideover", { static: true })
  autoEffectsSlideover: CSlideOverComponent;

  autoEffects = effects;

  // Id of creative we are editing
  id = null;

  introFinished: boolean = true;

  // Sidebar content
  specs: any = {};

  // Creative spec editor instance
  master: CreativesEditMasterService;
  editor: CreativesEditService = null;
  live: CreativesLiveService = null;

  // Video render flags
  videoSupport = false;
  selectedQuality = 4;

  outerCanvas = 9;

  // State flags
  isSaving = false;

  // Recipe callback when we wish to go to recipes after save
  recipeCreateCallback = null;

  // Extra settings
  extraSettings = { minimalUser: true };
  helpEnabled = true;

  // Video loading / preview
  currentProgress = 0;
  videoURL = null;

  // Preview filter
  selectedPreviewFilters = {};
  previewFilterOptions = [];

  // Layer preview stuff
  layerImages = [];
  isRefreshingLayers = false;
  layerRefreshTask = null;

  showSidebar = false;

  hasUnsavedChanges = false;

  /* Canvas */

  gridPosition = {
    left: 0,
    top: 0,
  };

  gridToolbarPosition = {
    left: 0,
    top: 0,
  };

  movePos: MousePosition = null;

  design: Creative;

  @ViewChild("gridToolbar") gridToolbar: ElementRef;
  @ViewChild("editorComponent") editorRef: CreativesEditEditorComponent;
  @ViewChild("previewDialog") previewDialog: TemplateRef<any>;

  /* End canvas */

  // Magic Effects
  selectedEffect = null;

  unsubscribe$ = new Subject();
  resetCallback$ = new Subject();

  demoMode = false;
  demoModePreviewClicked = false;

  elementsOverview: ElementOverviewSection[];

  formats: Record<string, DesignFormatSpec> = {
    "1_1": { display: "1:1", key: "1_1", resolution: [1080, 1080] },
  };
  formatsList: DesignFormatSpec[] = [
    { display: "1:1", key: "1_1", resolution: [1080, 1080] },
  ];

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    // For navigation from creative automation
    // const spec: CreativeSpec = this.router.getCurrentNavigation().extras.state as CreativeSpec;
    // if (spec != null) {
    //   this.loadEditorService({
    //     name: "",
    //     spec: { sequences: spec.sections },
    //   });
    // }

    this.introFinished =
      localStorage.getItem("designIntroShown") === "YES" ||
      (this.accessService.current?.account.state.has_designs ?? true);

    localStorage.setItem("designIntroShown", "YES");
  }

  // Init
  ngOnInit() {
    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.initializeEditor();
    });

    this.us.me(false).subscribe((me) => {
      this.demoMode = me.anon === true;
    });
  }

  ngOnDestroy() {
    this.master.destroy();
    this.resetCallback$.next(true);
    this.resetCallback$.complete();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  syncLayer(layer: string, key: string) {
    this.master.syncLayer(layer, key);
  }

  loadEditorService(design: Design = null) {
    const ps: CreativePreviewSettings = design ? design.preview_settings : {};

    // Saved preview filters
    const f = ps.preview_filters;
    this.selectedPreviewFilters = f ? f : {};

    const company = this.accessService.current.company.id;
    const account = this.accessService.current.account.id;
    const proctor = this.proctor.info.proctorModeActive()
      ? this.proctor.info.currentProctorCompany.id
      : null;

    this.master = new CreativesEditMasterService(
      design,
      this.formats,
      company,
      account,
      proctor,
      this.accessService.current.account.has_feed,
    );

    setTimeout(() => {
      this.editor = this.master.editor;
      this.live = this.master.live;

      this.setupCallbacks();

      this.editorRef.configureResolution();
    });
  }

  migrateSpec(spec) {
    const specCopy = JSON.parse(JSON.stringify(spec));
    const uuid = uuid4();
    return {
      default: uuid,
      specs: {
        [uuid]: specCopy,
      },
      formats: [
        {
          key: "1_1",
          resolution: null,
          uuid: uuid,
          type: CreativeEditorMode.IMAGE,
        },
      ],
    };
  }

  initializeEditor() {
    this.loadEditorSettings();
    this.loadPreviewFilterOptions();

    // Feature enables
    this.extraSettings.minimalUser = false;

    if (this.accessService.hasFeature("video")) {
      this.videoSupport = true;
    }

    // Video spec load

    this.designsService
      .getVideoSpecs()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          this.specs = res.specs;
          this.formatsList = res.formats;
          this.formatsList.push({
            display: "Video",
            key: "video",
            resolution: [1080, 1080],
          });
          this.formats = {};
          res.formats.forEach((format) => {
            this.formats[format.key] = format;
          });

          // Load creative if id given
          this.route.paramMap
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((pm) => {
              const id = pm.get("id");

              const state = window.history.state;
              const routerSpec = state?.spec ?? null;
              const oldSpec = routerSpec?.specs == null;

              if (id == null) {
                // Nothing to load
                // Load empty editor if needed
                if (!this.editor) {
                  this.loadEditorService(
                    routerSpec != null
                      ? {
                          name: "New Design",
                          spec: oldSpec
                            ? this.migrateSpec(routerSpec)
                            : routerSpec,
                          preview_settings: {},
                          live_image_cache: {},
                        }
                      : null,
                  );
                }

                // Check if recipe callback exists
                this.checkRecipeCallback();
                return;
              }

              // Load creative from API
              this.id = id;
              this.designsService
                .getCreatives(this.id, true)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                  next: (res) => {
                    this.loadEditorService(res);
                  },
                  error: (_err) => {
                    this.router.navigate(["/designs"]);
                  },
                });
            });
        },
      });
  }

  back() {
    this.router.navigate(["/designs"]);
  }

  // Hooking up event changes when creative is edited
  setupCallbacks() {
    this.master.formatChange$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (_) => {
        this.editorRef.resolutionChanged();
      },
    });

    this.editor.layerChanged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((layer) => {
        // Do a change detection on layer change, to make sure all settings and defaults sync
        this.ref.detectChanges();
      });

    // Live on backend raises error
    zip(this.master.error$, this.live.errorRaised$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((error) => {
        if (error[1] != null) {
          this.master.raisedError = error[1];
          return;
        }
        if (error[0] != null) {
          this.master.raisedError = error[0];
          return;
        }
        this.master.raisedError = null;
      });

    this.editor.history.createdHistoryPoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => {
        this.hasUnsavedChanges = true;
      });
  }

  // Product preview filters
  loadPreviewFilterOptions() {
    this.fs
      .getFields()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.previewFilterOptions = res;
      });
  }

  // Help settings / flag change
  helpChanged(to: boolean) {
    this.helpEnabled = to;
    localStorage["editorHelpEnabled"] = to;
  }

  // Load user-specific editor settings
  loadEditorSettings() {
    const h = localStorage["editorHelpEnabled"];
    if (h === undefined) {
      return;
    }
    this.helpEnabled = h === "true";
  }

  // Check if we have a recipe callback as query param and set it
  checkRecipeCallback() {
    const qpm = this.route.snapshot.queryParamMap;
    if (qpm.has("recipeCallback")) {
      this.recipeCreateCallback = qpm.get("recipeCallback");
    }
  }

  // Save creative
  async save(callback = false) {
    if (this.editor.name === "") {
      return;
    }

    const creative: Design = this.master.getCleanDesign();
    creative.preview_settings = {
      preview_filters: creative.preview_settings.preview_filters,
      selectedResolution: this.master.selectedResolution.replace("_", ":"),
      resolution: this.editorRef.currentPreviewResolution,
      editorMode: this.master.editorMode,
    };

    creative.name = this.editor.name;

    if (this.id !== null) {
      creative["id"] = this.id;
    }

    this.designsService
      .saveCreative(creative)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          // Triggering a home update here, should be implemented later as a global event service
          this.ha.getData(true).subscribe();

          let created = false;
          if (this.id === null) {
            created = true;
            const url = this.router
              .createUrlTree([res["id"]], { relativeTo: this.route })
              .toString();

            this.location.replaceState(url);
            this.id = res["id"];
          }

          if (callback && this.recipeCreateCallback !== null) {
            this.router.navigate(
              ["automations", "create", this.recipeCreateCallback],
              { queryParams: { selectCreativeId: this.id } },
            );
          } else {
            this.hasUnsavedChanges = false;

            this.ps.success({
              title: "Saved!",
              autoCloseTimeout: 1500,
            });
            setTimeout(() => {
              this.validateVideo();
            }, 1500);
          }
        },
        error: (err) => {},
        complete: () => {},
      });
  }

  close() {
    if (this.demoMode) {
      this.router.navigate(["/designs/demo"]);
      return;
    }

    if (!this.hasUnsavedChanges) {
      this.router.navigateByUrl(
        this.master.design != null
          ? `/designs?folder_id=${this.master.design.ui_folder}`
          : "/designs",
      );
      return;
    }

    this.ps
      .warning({
        title: "You have unsaved changes",
        confirmText: "Close Anyway",
        cancelText: "Keep Editing",
      })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          if (res) {
            this.router.navigateByUrl(
              this.master.design != null
                ? `/designs?folder_id=${this.master.design.ui_folder}`
                : "/designs",
            );
          }
        },
      });
  }

  // Observable for rendering a video job with progres
  renderJobObservable(jobID) {
    return new Observable((observer) => {
      const action = setInterval(() => {
        this.designsService
          .getVideoStatus(jobID)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
            this.currentProgress = res.progress;
            if (res.status === "finished") {
              observer.next(res.result.url);
              clearInterval(action);
              observer.complete();
            } else if (res.status === "failed") {
              observer.error(res.error);
              clearInterval(action);
              observer.complete();
            }
          });
      }, 1000);
    });
  }

  validateVideo() {
    this.master.raisedError = null;

    const spec = this.editor.getSpec();
    const size = [50, 50];

    this.designsService
      .enqueueVideoDemo(spec, size, false, this.selectedPreviewFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res1) => {
        this.renderJobObservable(res1.job_id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: (_) => {},
            error: (error) => {
              this.master.raisedError = error;

              this.ps.error({
                title: "Saved with error",
                text: error.message,
                autoCloseTimeout: 4000,
              });
            },
          });
      });
  }

  showAutoEffects() {
    this.autoEffectsSlideover.expand();
  }

  freeTrial() {
    window.open("https://confect.io/start-free-trial/", "_blank");
  }
  endIntro(event: MouseEvent) {
    this.introFinished = true;
    this.dialogTransmitter.transmit({
      id: "preview",
      template: this.previewDialog,
      event: event,
      // postEvent: () => {
      //   this.dialogTransmitter.transmit({
      //     id: "elements",
      //     template: this.previewDialog,
      //   });
      // },
    });
  }
}
